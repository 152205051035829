<template>
    <div class="logo">
        <img src="@/assets/img/logo.jpg" style=" margin-right: 10px"/> {{$store.state.curIndex==1?'SoMe Music School':'嗦咪音乐'}}
    </div>
</template>

<script>
    export default {
        name:"LogoBar",
        data() {
            return {
            }
        },
        props: {
            logo: String
        },
    }
</script>

<style lang="less" scoped>
.logo {
    margin-left: 10px;
    display: flex;
  align-items: center;
  font-family: title_font;
  font-size: 24px;
  letter-spacing: 2px;
  //color: rgb(246,221,0);
  color: rgb(0,162,224);
 img {
   width: auto;
   height: 100px;
 }
}

//.logo .el-image {
//    width: auto;
//    height: 37px;
//}
</style>
