<template>
  <div id="Home">
<!--    <el-row class="rowPos">-->
<!--      <el-col :span="24" class="colPos">-->
<!--&lt;!&ndash;        <div class="is-background"></div>&ndash;&gt;-->
<!--&lt;!&ndash;        <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: auto;background-color: rgba(33,27,23,.21);">&ndash;&gt;-->
<!--        <div class="desc-wrapper">-->
<!--          <p>{{descInfo}}</p>-->
<!--        </div>-->
<!--        &lt;!&ndash;            <el-carousel height="600px" :interval=5000 style="z-index: -1;">&ndash;&gt;-->
<!--        &lt;!&ndash;              <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;            <el-carousel-item v-for="(item, index) in imgs" :key="index">&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;                <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;            </el-carousel-item>&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;        </el-carousel>&ndash;&gt;-->
<!--      </el-col>-->
<!--      &lt;!&ndash;      <div style="height: 600px;background-color: rgba(33,27,23,.21);"></div>&ndash;&gt;-->
<!--    </el-row>-->
    <el-row class="main-content">
      <div class="main-content-box">
        <div class="box-info">
          <h1>{{$store.state.curIndex==1?'SoMe Trial Lesson Information':'SoMe 试课须知'}}</h1>
          <p>{{$store.state.curIndex==1?'Thank you for choosing SoMe for your learning journey. To ensure a smooth trial lesson experience, please carefully read the following information:':'欢迎您选择 SoMe进行学习。为了确保您的试课体验顺利进行，请仔细阅读以下须知:'}}</p>
          <h2>{{$store.state.curIndex==1?'Appointment Process:':'预约流程'}}</h2>
          <ol>
            <li>{{$store.state.curIndex==1?'Please fill out the trial lesson appointment form on our official website or through our customer service platform.':'请在我们的官方网站或通过客服平台填写试课预约表格。'}}</li>
            <li>{{$store.state.curIndex==1?'After submitting your appointment details, please ensure your phone is reachable. as our staff will contact you as soon as possible.':'在提交预约信息后，请确保您的手机通讯畅通，我们的工作人员将在最短时间内与您取得联系。'}}</li>
          </ol>
          <h2>{{$store.state.curIndex==1?'Contact Information:':'联系方式提供:'}}</h2>
          <p>{{$store.state.curIndex==1?'You can also contact us directly through the following methods':'您也可以直接通过以下方式联系我们'}}:</p>
          <div style="display: flex"> <h3 style="">{{$store.state.curIndex==1?'Phone Appointment':'电话预约'}}：</h3><a style="color: rgb(0,162,224)" href="tel:+65 80398964">+65 80398964</a></div>
<!--          <div style="display: flex"> <h3 style="">微信预约：</h3><a style="color: rgb(0,162,224)" href="tel:82509956">80398964</a></div>-->
          <h2>{{$store.state.curIndex==1?'Trial Lesson Fee Information:':'试课费用说明:'}}</h2>
          <ol>
            <li>{{$store.state.curIndex==1?'Our trial lessons are paid experiences. Please refer to the relevant information in the appointment form for specific pricing details.':'我们的试听课为有偿体验课程,具体的收费标准请参考预约表单内的相关信息。'}}</li>
            <li>{{$store.state.curIndex==1?'Payment methods support online payments or bank transfers.':'支付方式支持在线支付或银行转账。'}}</li>
          </ol>
          <p style="margin-top: 30px">{{$store.state.curIndex==1?'Thank you for your cooperation,':'感谢您的配合，'}}{{$store.state.curIndex==1?'We look forward to meeting you at':'期待在'}} <strong>SoMe</strong> {{$store.state.curIndex==2?'与您相会!':'!'}}</p>
        </div>
        <div class="box-info">
<!--          <h3>For all other enquiries, please fill up the form below:</h3>-->
          <h1>{{$store.state.curIndex==1?'Trial Lesson Appointment Form':'试课表格'}}</h1>
          <div class="box-form">
            <el-form ref="submitForm" :model="form" label-position="top" :rules="formRules">
              <el-form-item :label="`${$store.state.curIndex==1?'Student\'s Name (required)':'学生姓名(中/英)：'}`" prop="userName">
                <el-input v-model="form.userName" clearable></el-input>
              </el-form-item>
              <el-form-item :label="`${$store.state.curIndex==1?'Gender':'性别'}：`" prop="sex">
                <el-radio-group v-model="form.sex">
                  <el-radio :label="`${ $store.state.curIndex==1?'boy':'男'}`"></el-radio>
                  <el-radio :label="`${ $store.state.curIndex==1?'girl':'女'}`"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="`${$store.state.curIndex==1?'Music LessonS':'试课课程'}：`" prop="course">
                <el-checkbox-group v-model="form.course">
                  <el-checkbox :label="`${$store.state.curIndex==1?'Piano':'钢琴课'}`" name="type"></el-checkbox>
                  <el-checkbox :label="`${$store.state.curIndex==1?'Violin':'小提琴课'}`" name="type"></el-checkbox>
                  <el-checkbox :label="`${$store.state.curIndex==1?'Piano Partner':'钢琴陪练课'}`" name="type"></el-checkbox>
                  <el-checkbox :label="`${$store.state.curIndex==1?'Music Theory':'乐理课'}`" name="type"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item :label="`${$store.state.curIndex==1?'Parent\'s Mobile Number(required)':'学生/家长的联系方式（手机号）'}：`" prop="phone">
                <el-input v-model="form.phone" type="number" clearable></el-input>
              </el-form-item>
              <el-form-item :label="`${$store.state.curIndex==1?'Parent\'s  Email Address':'学生/家长的联系方式（邮箱）'}：`" prop="email">
                <el-input v-model="form.email" clearable></el-input>
              </el-form-item>
              <el-form-item :label="`${$store.state.curIndex==1?'Day and Time for a Class or Trial':'想要试课的时间（例，周三xx点-xx点）'}：`" prop="reservationTime">
                <el-input v-model="form.reservationTime" clearable></el-input>
              </el-form-item>
              <el-form-item :label="`${$store.state.curIndex==1?'Expected Teaching Method (lf Any)':'期望教学方式'}：`" prop="teachingWay">
                <el-input v-model="form.teachingWay" clearable></el-input>
              </el-form-item>
              <el-form-item :label="`${$store.state.curIndex==1?'Past Music Experience (lf Any)':'过往学习经历'}：`" prop="studyExperience">
                <el-input type="textarea" v-model="form.studyExperience" :rows="4"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="info" size="medium" @click="onSubmit">{{$store.state.curIndex==1?'submit':'提交'}}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>


        <!--        <div class="box-jump">-->
        <!--          <p>If you have further questions about our Music Theory classes or would like to see an outline of the curriculum, please contact us. We’d be happy to answer all of your questions!</p>-->
        <!--          <div class="jump-buttons">联系我们</div>-->
        <!--        </div>-->
      </div>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "HomeIndex",
  data() {
    return {
      formRules: {
        userName: [
          { required: true, message: '请输入学生姓名', trigger: ['blur','change']}
        ],
        phone: [
          { required: true, message: '请输入学生/家长的联系方式（手机号）', trigger: ['blur','change']}
        ],
      },
      form: {
        course: []
      },
      imageUrl: '',
      descInfo: '试课申请',

      acctivateName: "small",
      aiTitleSmallList: [],
      activeIndex: 0,
      carouselItems1:"",
      carouselItems2: "",
      applyObj: {},
      currentPage: 1,
      pageSize: 2,
      type: 10,
      imgs: [],
      homepageSceneList:[],
      background:[],

    }
  },
  props: {},
  methods: {
    imgPath() {
      return 'http://hjjai.com:6789/commonImg/'
    },
    steCarou(nub){
      this.carouselItems1 = this.carouselItems[nub].title;
      this.carouselItems2 = this.carouselItems[nub].content;
      console.log(this.carouselItems[nub].content);
      console.log(this.carouselItems[nub].title);

    },
     onSubmit() {
      this.$refs.submitForm.validate(async (valid) => {
        if (valid) {
          let data = this.form
          data.course = data.course.join(',')
          const result = await axios.post('https://piano.100yueoo.com/prod-api/course', data)
          if(result.data.code=='200') {
            this.$store.commit('setIndex',6)
           this.$router.push({path:'/SuccessViews'})
          }else {
            this.$message.error(result.data.msg)
          }
        }
      })
    }

  },
  async mounted() {
    // window.addEventListener("load", () => {
    //     this.$store.commit("setIndex", "1")
    // })
    // console.log(this.imgs)
    // 获取首页场景
    // let homepageScene = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 3,
    //         type: 14
    //     }
    // })
    // this.homepageSceneList = homepageScene.data.data.data
    // let background = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 1,
    //         type: 12
    //     }
    // })
    // this.backgroundList = background.data.data.data
    console.log(this.homepageSceneList);
  },
  components: {
  }
}
</script>

<style lang="less" scoped>
.colPos {
  position: relative !important;
  height: 600px;
  background-color: #1f1f1f;
  .is-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(33, 27, 23, .21);
  }
  img {
    //position: relative;
    //
    //top: -260px;
    margin-top: -13%;
  }

}

.main-content {
  background: #ffffff;
  padding-top: .8em;
  margin-bottom: 3em;
  .main-content-box {
    max-width: 1020px;
    width: auto;
    padding:0 28px !important;
    margin: auto;
    .imgLists {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      flex-wrap: wrap;
      .imgLists-item {
        width: 31%;
        img {
          width: 100%;
          margin-bottom: .6em;
        }
        .p1 {
          white-space: pre-wrap;
          font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
          font-weight: 300;
          font-style: normal;
          letter-spacing: .02em;
          text-transform: none;
          line-height: 1.2em;
          color: #000;
          font-size: 19px;
        }
        .p2 {
          color: #000;
          white-space: pre-wrap;
          font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
          font-weight: 300;
          font-style: normal;
          letter-spacing: .01em;
          text-transform: none;
          line-height: 1.7em;
          font-size: 13px;
        }
      }
    }
    .box-imgs {
      margin: 2.2em 0;
      img {
        width: 100%;
        height: auto;
      }
    }
    ol {
      margin-left: 36px;
    }
    h1 {
      margin: 3.5em 0 .8em;
      white-space: pre-wrap;
      text-align: center;
      color:  rgb(0,162,224);
      font-weight: 600;
      font-style: normal;
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-size: 30px;
      letter-spacing: 3px;
      text-transform: none;
      line-height: 1.2em;
    }
    h2 {
      margin: 1.8em 0 .8em;
      white-space: pre-wrap;
      color:  #000;
      font-weight: 600;
      font-style: normal;
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-size: 24px;
      letter-spacing: 3px;
      text-transform: none;
      line-height: 1.2em;
    }
    .box-info {
      margin-bottom: 3.8em;
    }
    .box-info-item {
      margin-top: 3.5em;
    }
    h3 {
      margin: 0.1em 0 .75em;
      white-space: pre-wrap;
      color:  #000;
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 600;
      font-style: normal;
      font-size: 17px;
      letter-spacing: 3px;
      text-transform: uppercase;
      line-height: 1.3em;
    }
    p,ol li,a {
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 300;
      font-style: normal;
      font-size: 17px;
      letter-spacing: 2px;
      line-height: 1.6em;
      color: #000;
      white-space: pre-wrap;
      margin-bottom: .66em;
    }
    ul {
      margin-left: 36px;
      li {
        margin: 0.5em 0;
      }
    }
    .box-jump {
      margin: 2em 0;
      .jump-buttons {
        width: 150px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #111;
        border: 2px solid #111;
        font-size: 16px;
        border-radius: 2px;
        margin: 2.6em auto;
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }
  }
}
.desc-wrapper {
  animation: feature-text-anim .75s ease-in-out;
  top: 52%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-42%);
  width: 100%;
  max-width: 956px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  p{
    white-space: pre-wrap;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 400;
    font-style: normal;
    font-size: 56px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.3em;
    color: #fff;
    margin: 20px auto;
    -webkit-transform: translatez(0);
  }
}
/deep/ .el-form--label-top .el-form-item__label {
  font-size: 20px;
  white-space: pre-wrap;
  color: #000;
  font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
  font-weight: 400;
}
/deep/ .el-checkbox {

  display: block;

  .el-checkbox__label {
    font-size: 15px;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    white-space: pre-wrap;
    color: #000;
  }
}
/deep/ .el-radio {

  display: block;
  margin-bottom: 20px;

  .el-radio__label {
    font-size: 15px;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    white-space: pre-wrap;
    color: #000;
  }
}
/deep/ .el-button--medium{
  font-size: 20px;
  font-family: Microsoft YaHei;
  background: #000;
  padding: 20px 38px;
}
</style>

