<!-- eslint-disable vue/valid-v-for -->
<template>
<div class="Navmenu">
    <el-menu :default-active="$store.state.index" :mode="$store.state.showMenu ? 'vertical':'horizontal'" active-text-color="rgb(0,162,224)">
        <template v-for="menu in menuList">
            <el-menu-item v-if="!menu.lists" :index="menu.index" :name="menu.index" :path="menu.path" @click="parentClick($event)" :id="menu.id">{{ menu.title }}</el-menu-item>
          <el-submenu :index="menu.index" v-else>
            <template slot="title">{{menu.title}}</template>
            <el-menu-item v-for="menu2 in menu.lists" :index="menu2.index" :name="menu2.index" :path="menu2.path" @click="parentClick($event)" :id="menu.id">{{menu2.title}}</el-menu-item>
          </el-submenu>
        </template>
    </el-menu>

  <div class="changeType" v-if="!$store.state.showMenu">
    <div :class="{'ligntItem':$store.state.curIndex==1}" style="margin-right: 20px;cursor: pointer" @click="changeType(1)">EN</div>
    <div style="cursor: pointer" :class="{'ligntItem':$store.state.curIndex==2}" @click="changeType(2)">中</div>
  </div>
</div>
</template>

<script>
export default {
    name: "NavMenu",
    data() {
        return {
            curIndex: this.$store.state.curIndex,
            active: this.activeValue
        };
    },
    props: {
        menuList: Array,
        activeValue: String
    },
    created() {},
    mounted() {
    },
    methods: {
        parentClick(event) {
            // console.log(event);
             this.$emit('topClick', event)

        },
      changeType(type) {
          this.$store.commit('setCurIndex',type)
      }
    },
};
</script>

<style lang="less" scoped>
.Navmenu {
  display: flex;
  align-items: center;
  .changeType {
    margin-left: 15px;
    width: 60px;
    display: flex;
    color: #666;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-size: 16px;
    .ligntItem {
      font-weight: bold;
      color: rgba(0,162,224,1)
    }
  }
}
.el-menu {
    border: none !important;
}

//.Navmenu .el-menu-item:not(.is-disabled):hover {
//    border-bottom: 2px solid #409eff !important;
//}

.el-menu-item.is-active{
    font-weight: bold !important;
}
.el-menu-item{
  text-transform: uppercase;


    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
  font-size: 15px;
}
 /deep/ .el-submenu .el-submenu__title {
   text-transform: uppercase;
  font-family:Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica !important;
   font-size: 15px;
  color: #1f1f1f;

}
</style>
