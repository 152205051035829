<template>
  <div id="Home">
    <el-row class="rowPos">
      <el-col :span="24" class="colPos">
        <div class="is-background"></div>
<!--        <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: auto;">-->
        <div class="desc-wrapper">
          <p>{{descInfo}}</p>
        </div>
        <!--            <el-carousel height="600px" :interval=5000 style="z-index: -1;">-->
        <!--              <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">-->
        <!--&lt;!&ndash;            <el-carousel-item v-for="(item, index) in imgs" :key="index">&ndash;&gt;-->
        <!--&lt;!&ndash;                <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">&ndash;&gt;-->
        <!--&lt;!&ndash;            </el-carousel-item>&ndash;&gt;-->
        <!--        </el-carousel>-->
      </el-col>
    </el-row>
    <el-row class="main-content">
      <div class="main-content-box">
        <div class="box-info">
          <h1>School Policies</h1>
          <p>The following details the Terms and Conditions regarding our Music Lesson Services. Please contact us should you need any clarification.</p>
        </div>
        <div class="box-info">
          <p><strong>Definitions of terms used in this document as follows:</strong></p>
          <p>The School - Refers to Joy Music Studio (Marine Parade) Pte. Ltd. </p>
          <p>Your Teacher(s) – The Teacher assigned to you for your music lessons.  </p>
          <p>School Calendar – Joy Music Studio’s internal School Calendar located on our website. </p>
          <p>Student(s) – The enrolled student or parent/legal guardian (if the student is under 18) as stated on the registration form upon enrolment. </p>
          <p>Lesson Slot – Fixed weekly lesson schedule (day and time), agreed upon by the Student and The School upon enrolment. </p>
          <p>Make-Up Credit(s) – Replacement lesson for lessons missed. </p>
          <p>Credit Note – Credited value for lesson(s) missed. </p>
        </div>
        <div class="box-info">
          <p><strong>1 - Enrolment</strong></p>
          <p>1.1   Students are required to pay the stated Registration Fee at enrolment.</p>
          <p>1.2   Students are required to pay the stated Deposit at enrolment which is refundable with 30-days’ notice of withdrawal.</p>
          <p>1.3   Upon enrolment, Student(s) are deemed to have read and agreed to our Terms & Conditions.</p>
        </div>
        <div class="box-info">
          <p><strong>2 - Billing </strong></p>
          <p><strong>For Individual Regular Weekly Lessons: </strong></p>
          <p>2.1   Fees are charged on a recurring monthly basis. Fees will be pro-rated accordingly for public holidays, and school closure days where they occur. Please view our School Calendar for specific dates.</p>
          <p>2.2   All fees are due and payable on the date stated on the invoice. </p>
          <p>2.3   A late payment fee of $10 will be chargeable to the Student’s invoice if payment for that month is not made within 14 days from the invoice due date. </p>
          <p><strong>For Music Explorer (Individual Lessons) or Adult Flexi Packages:</strong></p>
          <p>2.4   The School reserves the right to terminate the Student’s enrolment and release your Lesson Slot to the public if the invoice is not paid within 30 days of the invoice due date.</p>
          <p>2.5   Fees are non-refundable and non-transferrable, except siblings or immediate family members.</p>
          <p>2.6   All lesson packages must be paid in full by the date of the first scheduled lesson.</p>
          <p><strong>For Music Explorer (Group Lessons):</strong></p>
          <p>2.7   All lessons in the package must be completed by the stated expiry date.</p>
          <p>2.8   There will be no refunds issued for expired lessons. Students may wish to appeal to extend the deadline via our Appeal Form on our website.</p>
        </div>
        <div class="box-info">
          <p><strong>3 - Cancellation of Lessons</strong></p>
          <p><strong>For All Lessons Except Music Explorer Group Lessons:</strong></p>
          <p>3.1   Cancellation of any lessons can be made by notifying The School at least 24 hours before the affected lesson. For non-urgent cancellations such as travel, school exams or activities, we request 7 days advance notice. The School
            will issue a Make-up Credit and schedule a make-up lesson for the student during school make-up days or another pre-arranged time. Please see our School Calendar for more details.</p>
          <p>3.2   Last-minute cancellations (less than 24-hour notice) will result in forfeiture of the lesson unless a Medical Certificate is provided.
            Students may wish to appeal to reinstate the lesson via our Appeal Form on our website. </p>
          <p>3.3   In case of cancellation of lessons due to Your Teacher’s unavailability (such as medical, reservist, or personal leave), The School will provide a substitute teacher, or issue a Make-up Credit and arrange the
            make-up lesson with you accordingly, or issue a Credit Note to be applied for next month’s fees at our sole discretion. </p>
        </div>
        <div class="box-info">
          <p><strong>4 - Temporary Suspension of Lessons </strong></p>
          <p>4.1   Students may request a temporary suspension of lessons for circumstances such as hospitalization, travel, bereavement, reservists, or major examinations; please submit the Temporary Suspension Form to
            The School along with your reason and supporting documents at least 14 days prior to the suspension start date.  </p>
          <p>4.2   During temporary suspension, the Student’s Lesson Slot will be retained for a period of up to 30 days.  After 30 days, The School reserves the right to release your Lesson Slot to the public. To extend this period for another 30 days, an advance payment equivalent to one (1) month’s lesson fees is required.
            This payment is non-refundable if the student does not resume lessons. Lesson slots cannot be retained for more than 60 days. </p>
          <p>4.3   To resume lessons, advance notice in writing must be submitted to The School at least 14 days prior to the desired start date. </p>
        </div>
        <div class="box-info">
          <p><strong>5 – Withdrawal of Lessons  </strong></p>
          <p>5.1    Withdrawal of lessons must be notified to The School at least 30 days in advance of the withdrawal date by submitting the Withdrawal Request Form.   </p>
          <p>5.2    The Student Deposit will be refunded to the student if: </p>
          <p style="margin-left:40px;" class="">i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Withdrawal Request Form is submitted at least 30 days before the withdrawal date </p>
          <p style="margin-left:40px;" class="">ii)&nbsp;&nbsp;&nbsp; All outstanding invoices are paid</p>
          <p>The School reserves the right to use the Student Deposit to offset any outstanding invoices owed. </p>
          <p>5.3    The Student must complete all paid lessons and unused Make-Up Credits during the 30-day notice period. There is no refund for unused lessons or Make-up Credits.</p>
        </div>
        <div class="box-info">
          <p><strong>6 - Media Release  </strong></p>
          <p>6.1   The Student understands that The School may take photos and videos at studio, events, or activities to be used for training, marketing, or documentation purposes.   </p>
          <p>6.2   The Student grants permission for The School to use photographs, videos, or other digital media containing their likeness in all our publications, including web-based publications, without payment or other consideration.
            <em>We will try but cannot guarantee that we will be able to inform the student that they are in a photo prior to use.</em> </p>
        </div>
      </div>
    </el-row>




  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "HomeIndex",
  data() {
    return {
      imageUrl: '',
      descInfo: '请假规定',


    }
  },
  props: {},
  methods: {
    imgPath() {
      return 'http://hjjai.com:6789/commonImg/'
    },
    steCarou(nub){
      this.carouselItems1 = this.carouselItems[nub].title;
      this.carouselItems2 = this.carouselItems[nub].content;
      console.log(this.carouselItems[nub].content);
      console.log(this.carouselItems[nub].title);

    },
    changeIn(val){
      // console.log(val);
      this.steCarou(val)
    },
    boxFiveDemo(event) {
      let obj = this.boxFive.filter((item) => {
        return item.id.toString() === event.target.id;
      });
      this.applyObj = obj[0];
      this.boxFive[event.target.id-1].Icon=true
    },

  },
  async mounted() {
    // this.applyObj = this.boxFive[0];
    // window.addEventListener("load", () => {
    //     this.$store.commit("setIndex", "1")
    // })
    const result = await axios.get('http://hjjai.com:6789/img/getImgByType', {
      params: {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        type: this.type
      }
    })
    this.imgs = result.data.data.data
    // console.log(this.imgs)
    // 获取首页场景
    // let homepageScene = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 3,
    //         type: 14
    //     }
    // })
    // this.homepageSceneList = homepageScene.data.data.data
    // let background = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 1,
    //         type: 12
    //     }
    // })
    // this.backgroundList = background.data.data.data
    console.log(this.homepageSceneList);
  },
  components: {
  },
  computed: {
    currentContent(){
      return this.carouselItems[this.activeIndex].content;
    }
  }
}
</script>

<style lang="less" scoped>
.colPos {
  position: relative !important;
  height: 600px;
  overflow: hidden;
  .is-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(33, 27, 23, .21);
  }
  img {
    //position: relative;
    //
    //top: -260px;
    margin-top: -13%;
  }

}


.main-content {
  background: #ffffff;
  padding-top: 1.8em;
  .box-info {
    margin: 3em 0;
  }
  .main-content-box {
    max-width: 1020px;
    width: auto;
    padding:0 28px !important;
    margin: auto;
    p,ul li {
      font-family: europa;
      font-weight: 300;
      font-style: normal;
      font-size: 19px;
      letter-spacing: .5px;
      line-height: 1.6em;
      color: #1f1f1f;
      white-space: pre-wrap;
      margin-bottom: .8em;
    }
    h1 {
      color:  rgb(0,162,224);
      font-weight: bold;
      font-style: normal;
      font-size: 32px;
      letter-spacing: 0px;
      text-transform: none;
      line-height: 1.2em;
      margin: 1em 0 .5em;
    }
  }
}
.desc-wrapper {
  animation: feature-text-anim .75s ease-in-out;
  top: 52%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-42%);
  width: 100%;
  max-width: 956px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  p {
    white-space: pre-wrap;
    font-family: futura-pt;
    font-weight: 300;
    font-style: normal;
    font-size: 57px;
    letter-spacing: 4px;
    text-transform: uppercase;
    line-height: 1.3em;
    color: #fff;
    margin: 20px auto;
    -webkit-transform: translatez(0);
  }
}
</style>
