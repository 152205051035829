<template>
  <div id="Home">
    <el-row class="rowPos">
      <el-col :span="24" class="colPos">
                <div class="is-background"></div>
        <img src="@/assets/img/tiqin1.png" style="width: 100%;height: auto;">
        <div class="desc-wrapper">
          <p>{{$store.state.curIndex==2?'小提琴课':'Violin Lessons'}}</p>
        </div>
        <!--            <el-carousel height="600px" :interval=5000 style="z-index: -1;">-->
        <!--              <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">-->
        <!--&lt;!&ndash;            <el-carousel-item v-for="(item, index) in imgs" :key="index">&ndash;&gt;-->
        <!--&lt;!&ndash;                <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">&ndash;&gt;-->
        <!--&lt;!&ndash;            </el-carousel-item>&ndash;&gt;-->
        <!--        </el-carousel>-->
      </el-col>
    </el-row>
    <el-row class="main-content">
      <div class="main-content-box">
        <div class="box-info">
          <p style="text-align: left;text-indent: 2em;">{{$store.state.curIndex==1?'Music education is not just the transmission of skills. it is an investment in a child\'s overall development. Every child can discover their own talent in SoMe, allowing music to become a bridge that connects the past with the future and transcends borders.':'音乐教育不仅仅是技艺的传授，更是对孩子全面发展的投资。在这里，每个孩子都能找到属于自己的旋律，让音乐成为连接过去与未来、跨越国界的桥梁。'}}</p>
          <h1>{{$store.state.curIndex==2?'小提琴课':'Violin Lessons'}}</h1>
          <div><h3>{{$store.state.curIndex==1?'Long-Term Plan':'长期规划'}}：</h3> <p>{{$store.state.curIndex==1?'We design personalized, long-term music learning plans for your child, aimed at comprehensively enhancing their musical talent and artistic cultivation.':'为您的孩子制定个性化的长期音乐学习计划，旨在全面提升其音乐才能与艺术修养。'}}</p></div>
          <div><h3>{{$store.state.curIndex==1?'Comprehensive curriculum':'综合教学'}}：</h3> <p>{{$store.state.curIndex==1?'Our courses integrate music theory, music history, and classical music appreciation, allowing students to experience the beauty and depth of music while receiving technical training.':'我们的课程将乐理知识、音乐历史与名曲赏析相结合，使学生在技术训练的同时，深刻体验音乐的美妙与内涵。'}}</p></div>
          <div><h3>{{$store.state.curIndex==1?'CSystematic Teaching':'系统教学体系'}}'：</h3> <p>{{$store.state.curIndex==1?'Each student will receive full support from a team of professional teachers, ensuring that every lesson achieves optimal teaching outcomes.':'每位学生都将得到由专业教师组成的团队的全方位支持，确保每次课程都能实现最优的教学成果。'}}</p></div>
        </div>
        <div class="imgLists">
          <!--            <div v-for="(item,i) in imgList" :key="i" class="imgLists-item">-->
          <div class="imgLists-item">
            <img src="@/assets/img/tiqin3.png">
          </div>
          <div class="imgLists-item imgLists-item2">
            <img src="@/assets/img/tiqin2.png">
            <!--            <img src="@/assets/img/ganqin2.png" >-->
          </div>
          <!--              <div class="imgLists-item">-->
          <!--                <img src="@/assets/img/m4.png" >-->
          <!--              </div>-->
          <!--              <p class="p1">{{item.name}}</p>-->
          <!--            </div>-->
        </div>
        <!--        <div class="box-jump">-->
        <!--          <p>If you have further questions about our Music Theory classes or would like to see an outline of the curriculum, please contact us. We’d be happy to answer all of your questions!</p>-->
        <!--          <div class="jump-buttons">联系我们</div>-->
        <!--        </div>-->
      </div>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "HomeIndex",
  data() {
    return {
      inds: this.$route.query.ind,
      imageUrl: '',
      descInfo: '小提琴课',
      aiTitleSmallList: [],
      activeIndex: 0,
      carouselItems1:"",
      carouselItems2: "",
      applyObj: {},
      currentPage: 1,
      counts: 0,
      pageSize: 2,
      type: 10,
      imgs: [],
      homepageSceneList:[],
      background:[],

    }
  },
  props: {},
  methods: {
    imgPath() {
      return 'http://hjjai.com:6789/commonImg/'
    },
    steCarou(nub){
      this.carouselItems1 = this.carouselItems[nub].title;
      this.carouselItems2 = this.carouselItems[nub].content;
      console.log(this.carouselItems[nub].content);
      console.log(this.carouselItems[nub].title);

    },
    changeIn(val){
      // console.log(val);
      this.steCarou(val)
    },
    boxFiveDemo(event) {
      let obj = this.boxFive.filter((item) => {
        return item.id.toString() === event.target.id;
      });
      this.applyObj = obj[0];
      this.boxFive[event.target.id-1].Icon=true
    },

  },
  // activated() {
  //   // window.scrollTo({
  //   //   top: 0,
  //   //   left: 0,
  //   //   behavior: 'smooth' // 可选，平滑滚动效果
  //   // });
  //
  //   // this.$forceUpdate()
  //   // console.log('000',this.inds)
  //   if(this.inds) {
  //
  //     var element = document.getElementById('elementId1');
  //     var distance = element.offsetTop;
  //     // var distance = parseFloat(style.getPropertyValue('top'));
  //
  //     console.log('distance',distance)
  //     if(this.counts==0) {
  //       window.scrollTo({
  //         top: distance+542,
  //         left: 0,
  //         behavior: 'smooth' // 可选，平滑滚动效果
  //       });
  //     }else {
  //       window.scrollTo({
  //         top: distance+540,
  //         left: 0,
  //         behavior: 'smooth' // 可选，平滑滚动效果
  //       });
  //     }
  //
  //     this.counts++
  //     this.$forceUpdate()
  //   }
  // },
  async mounted() {
    // this.applyObj = this.boxFive[0];
    // window.addEventListener("load", () => {
    //     this.$store.commit("setIndex", "1")
    // })
    const result = await axios.get('http://hjjai.com:6789/img/getImgByType', {
      params: {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        type: this.type
      }
    })
    this.imgs = result.data.data.data
    // console.log(this.imgs)
    // 获取首页场景
    // let homepageScene = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 3,
    //         type: 14
    //     }
    // })
    // this.homepageSceneList = homepageScene.data.data.data
    // let background = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 1,
    //         type: 12
    //     }
    // })
    // this.backgroundList = background.data.data.data
    console.log(this.homepageSceneList);
  },
  components: {
  },
  computed: {
    // currentContent(){
    //   return this.carouselItems[this.activeIndex].content;
    // }
  }
}
</script>

<style lang="less" scoped>
.colPos {
  position: relative !important;
  height: 600px;
  overflow: hidden;
  .is-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(33, 27, 23, .21);
  }
  img {
    //position: relative;
    //
    object-position: 100% 12.40276909221658%;
    //top: -260px;
    margin-top: -13%;
  }

}

.main-content {
  background: #ffffff;
  padding-top: .8em;
  margin-bottom: 2.8em;
  .main-content-box {
    max-width: 1020px;
    width: auto;
    padding:0 28px !important;
    margin: auto;
    .imgLists {
      display: flex;
      margin-top: 30px;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .imgLists-item {
        width: 48.8%;
        img {
          width: 100%;
          height: 360px;
          margin-bottom: .7em;
        }
        .p1 {
          white-space: pre-wrap;
          font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 2px;
          text-transform: none;
          line-height: 1.2em;
          color: #000;
          font-size: 18px;
        }
        .p2 {
          color: #1f1f1f;
          white-space: pre-wrap;
          font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
          font-weight: 300;
          font-style: normal;
          letter-spacing: 2px;
          text-transform: none;
          line-height: 1.6em;
          font-size: 12px;
        }
      }

    }
    .box-info {
      margin-top: 3.2em;
    }
    .box-imgs {
      margin: 3em 0;
      img {
        width: 100%;
        height: auto;
      }
    }
    h1 {
      margin: 2.7em 0 .92em;
      white-space: pre-wrap;
      text-align: center;
      color:  rgb(0,162,224);
      font-weight: 400;
      font-style: normal;
      font-size: 30px;
      letter-spacing: 2px;
      text-transform: none;
      line-height: 1.2em;
    }
    h2 {
      margin: 1em 0 .8em;
      white-space: pre-wrap;
      color:  rgb(0,162,224);
      font-weight: 400;
      font-style: normal;
      font-size: 30px;
      letter-spacing: 2px;
      text-transform: none;
      line-height: 1.2em;
    }
    h3 {
      display: inline-block !important;
      //margin: .4em 0 .3em;
      white-space: pre-wrap;
      color:#000;
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 600;
      font-style: normal;
      font-size: 18px;
      letter-spacing: 2px;
      text-transform: uppercase;
      line-height: 1.5em;
      float: left;
      vertical-align: baseline;
    }
    p,ul li {
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 300;
      font-style: normal;
      font-size: 17px;
      letter-spacing: 2px;
      line-height: 1.6em;
      color: #000;
      white-space: pre-wrap;
      margin-bottom: .72em;
    }
    ul {
      margin-left: 36px;
      li {
        margin: 0.5em 0;
      }
    }
    .box-jump {
      margin: 2em 0;
      .jump-buttons {
        width: 150px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #111;
        border: 2px solid #111;
        font-size: 16px;
        border-radius: 2px;
        margin: 2.6em auto;
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }
  }
}
.desc-wrapper {
  animation: feature-text-anim .75s ease-in-out;
  top: 52%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-42%);
  width: 100%;
  max-width: 956px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  p {
    white-space: pre-wrap;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 600;
    font-style: normal;
    font-size: 57px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.3em;
    color: #fff;
    margin: 20px auto;
    -webkit-transform: translatez(0);
  }
}
</style>
