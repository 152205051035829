import { render, staticRenderFns } from "./CurriculumSystem.vue?vue&type=template&id=16131c08&scoped=true"
import script from "./CurriculumSystem.vue?vue&type=script&lang=js"
export * from "./CurriculumSystem.vue?vue&type=script&lang=js"
import style0 from "./CurriculumSystem.vue?vue&type=style&index=0&id=16131c08&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16131c08",
  null
  
)

export default component.exports