<template>
  <div id="Home">
        <el-row class="rowPos">
          <el-col :span="24" class="colPos">
    <!--        <div class="is-background"></div>-->
    <!--        <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: auto;background-color: rgba(33,27,23,.21);">-->
            <div class="desc-wrapper">
              <p>{{$store.state.curIndex==1?'Client Testimonials':'客户评价'}}</p>
            </div>
            <!--            <el-carousel height="600px" :interval=5000 style="z-index: -1;">-->
            <!--              <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">-->
            <!--&lt;!&ndash;            <el-carousel-item v-for="(item, index) in imgs" :key="index">&ndash;&gt;-->
            <!--&lt;!&ndash;                <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">&ndash;&gt;-->
            <!--&lt;!&ndash;            </el-carousel-item>&ndash;&gt;-->
            <!--        </el-carousel>-->
          </el-col>
          <!--      <div style="height: 600px;background-color: rgba(33,27,23,.21);"></div>-->
        </el-row>
    <el-row class="main-content">
      <div class="main-content-box">
        <div class="box-info">
    <h1 style="text-align: center">
      {{$store.state.curIndex==1?'At SoMe Music School in Singapore, we believe that word-of-mouth is the best advertisement! That\'s why we have been collecting testimonials from satisfied clients over the years.':'在新加坡的 SoMe Music School，我们相信口碑是最好的广告！这就是为什么多年来我们一直在收集满意客户的推荐信。'}}
    </h1>

        </div>
        <el-divider></el-divider>
        <div class="box-info">
    <h1 style="text-align: center">
      以下是家长们对我们音乐学校的评价
    </h1>
<!--          <p>Emily 老师是孩子同校家长推荐的，在上她的课之前，我们已经和一位据说是当地知名的幼儿钢琴老师学习了，但是我的孩子回来和我说他不喜欢这位老师，十分抗拒上钢琴课。这期间，我们暂停钢琴课大约有半年。第一次试上Emily的课，孩子就很喜欢她，作为中国妈妈的我真得很挑剔😂，但也为Emily点赞。上了她的课后，我的孩子变得不再抗拒钢琴课，并且主动和我说会不间断联系钢琴。谢谢Emily让我的孩子继续延续这份对钢琴的欢喜之心。</p>-->
<!--          <el-divider></el-divider>-->
<!--    <p>Cecilia自从跟随SoMe音乐的Emily老师学习钢琴以来，通过各种训练钢琴演奏的技术水平进步非常大，顺利通过考级，各种比赛和表演也能从容应对。非常感谢SoMe音乐感谢Emily老师。</p>-->
<!--          <el-divider></el-divider>-->
          <p>作为一个非音乐专业的外行家长，孩子的钢琴课我前前后后试过很多老师的课，也走过一些弯路，直到遇到Emily老师和她的陪练老师邹老师，经过一段时间的学习，让我和孩子都收获了很多感动。孩子不光基本功扎实了很多，还能够依然保持对钢琴的热爱，姐姐学的我很满意，现在妹妹也开始跟两位老师学习，希望我的两个孩子都很跟着SoMe一同成长！</p>
          <el-divider></el-divider>
          <p>老师的课让我学会如何细致地把握曲子中的细节，理解曲子的背景以及不同段落应有的弹奏方式。通过老师绘声绘色的描述，我也能更准确地弹出曲子应有的节奏。</p>
          <el-divider></el-divider>
          <p>来新加坡后一直寻找专业的音乐老师和机构，有朋友介绍过不同的老师，有去一些规模很大的专业机构接触过，总是有这样那样的问题，今年有幸遇到Emily带领下这家机构，和老师沟通协商都很通畅，老师团队专业性强，耐心，和善，效率高，系统性强. 以后家里其它孩子学钢琴肯定会是选择她们</p>
          <el-divider></el-divider>
          <p>I am incredibly grateful for the positive impact Emily has had on my daughter's piano journey. Not only is she exceptionally skilled in teaching technical skills, but she also nurtures a deep appreciation for music across various genres.</p>
          <p>Emily encourages my daughter to fully understand and connect with the music before she plays, fostering a genuine love for the art. Her approach is firm and kind, striking the perfect balance that motivates my daughter to improve without feeling overwhelmed.</p>
          <p>Thanks to Emily, my daughter looks forward to each lesson and has blossomed into a more confident musician. I cannot recommend her highly enough!</p>
          <el-divider></el-divider>
          <p>Emily是S的好朋友。他们有共同的兴趣爱好。每周他都很期待和Emily一起学琴。虽然S才三岁而且刚接触音乐，但他已开始渐渐习惯上课、练习、掌握新概念的过程。很高兴看到他专注学习的样子，也很欣喜他对音乐越来越有兴趣！</p>
<!--          <p>作为一个非音乐专业的外行家长，孩子的钢琴课我前前后后试过很多老师的课，也走过一些弯路，直到遇到Emily老师和她的陪练老师邹老师，经过一段时间的学习，让我和孩子都收获了很多感动。孩子不光基本功扎实了很多，还能够依然保持对钢琴的热爱，姐姐学的我很满意，现在妹妹也开始跟两位老师学习，希望我的两个孩子都很跟着SoMe一同成长！</p>-->
<!--          <el-drawer></el-drawer>-->
<!--          <p>老师的课让我学会如何细致地把握曲子中的细节，理解曲子的背景以及不同段落应有的弹奏方式。通过老师绘声绘色的描述，我也能更准确地弹出曲子应有的节奏。</p>-->
        </div>
        <el-divider></el-divider>
        <div class="box-info">
    <h1 style="text-align: center">
      以下是学生的一些感谢信
    </h1>
          <div class="imgLists">
          <div class="imgLists-item">
          <img src="@/assets/img/comment.jpg" >

          </div>
          <div class="imgLists-item">
            <img src="@/assets/img/comment4.jpg">

          </div>
          </div>
          <div class="imgLists">
          <div class="imgLists-item" style="width: 100%">
          <img src="@/assets/img/comment3.png">

          </div>
          </div>
          <div class="imgLists">
          <div class="imgLists-item" style="width: 60%;margin: auto">
          <img src="@/assets/img/comment2.png">

          </div>
          </div>
          </div>



        <!--        <div class="box-jump">-->
        <!--          <p>If you have further questions about our Music Theory classes or would like to see an outline of the curriculum, please contact us. We’d be happy to answer all of your questions!</p>-->
        <!--          <div class="jump-buttons">联系我们</div>-->
        <!--        </div>-->
      </div>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "HomeIndex",
  data() {
    return {
      formRules: {
        userName: [
          { required: true, message: '请输入学生姓名', trigger: ['blur','change']}
        ],
        phone: [
          { required: true, message: '请输入学生/家长的联系方式（手机号）', trigger: ['blur','change']}
        ],
      },
      form: {
        course: []
      },
      imageUrl: '',
      descInfo: '客户评价',
      acctivateName: "small",
      aiTitleSmallList: [],
      activeIndex: 0,
      carouselItems1:"",
      carouselItems2: "",
      applyObj: {},
      currentPage: 1,
      pageSize: 2,
      type: 10,
      imgs: [],
      homepageSceneList:[],
      background:[],

    }
  },
  props: {},
  methods: {
    imgPath() {
      return 'http://hjjai.com:6789/commonImg/'
    },
    steCarou(nub){
      this.carouselItems1 = this.carouselItems[nub].title;
      this.carouselItems2 = this.carouselItems[nub].content;
      console.log(this.carouselItems[nub].content);
      console.log(this.carouselItems[nub].title);

    },
    onSubmit() {
      this.$refs.submitForm.validate(async (valid) => {
        if (valid) {
          let data = this.form
          data.course = data.course.join(',')
          const result = await axios.post('https://piano.100yueoo.com/prod-api/course', data)
          if(result.data.code=='200') {
            this.$store.commit('setIndex',6)
            this.$router.push({path:'/SuccessViews'})
          }else {
            this.$message.error(result.data.msg)
          }
        }
      })
    }

  },
   mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth' // 可选，平滑滚动效果
    });
    // window.addEventListener("load", () => {
    //     this.$store.commit("setIndex", "1")
    // })
    // console.log(this.imgs)
    // 获取首页场景
    // let homepageScene = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 3,
    //         type: 14
    //     }
    // })
    // this.homepageSceneList = homepageScene.data.data.data
    // let background = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 1,
    //         type: 12
    //     }
    // })
    // this.backgroundList = background.data.data.data
    console.log(this.homepageSceneList);
  },
  components: {
  }
}
</script>

<style lang="less" scoped>
.colPos {
  position: relative !important;
  height: 600px;
  background-color: #1f1f1f;
  .is-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(33, 27, 23, .21);
  }
  img {
    //position: relative;
    //
    //top: -260px;
    margin-top: -13%;
  }

}

.main-content {
  background: #ffffff;
  padding-top: .8em;
  margin-bottom: 3em;
  .main-content-box {
    max-width: 1020px;
    width: auto;
    padding:0 28px !important;
    margin: auto;
    .imgLists {
      display: flex;
      margin-top: 28px;
      justify-content: space-between;
      flex-wrap: wrap;
      .imgLists-item {
        width: 48.5%;
        img {
          width: 100%;
          margin-bottom: .6em;
        }
        .p1 {
          white-space: pre-wrap;
          font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
          font-weight: 300;
          font-style: normal;
          letter-spacing: .02em;
          text-transform: none;
          line-height: 1.2em;
          color: #000;
          font-size: 19px;
        }
        .p2 {
          color: #000;
          white-space: pre-wrap;
          font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
          font-weight: 300;
          font-style: normal;
          letter-spacing: .01em;
          text-transform: none;
          line-height: 1.7em;
          font-size: 13px;
        }
      }
    }
    .box-imgs {
      margin: 2.2em 0;
      img {
        width: 100%;
        height: auto;
      }
    }
    ol {
      margin-left: 36px;
    }
    h1 {
      margin: 1.5em 0 1em;
      white-space: pre-wrap;
      text-align: center;
      color:  rgb(0,162,224);
      font-weight: 300;
      font-style: normal;
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-size: 28px;
      letter-spacing: 2px;
      text-transform: none;
      line-height: 1.5em;
    }
    h2 {
      margin: 1.8em 0 .8em;
      white-space: pre-wrap;
      color:  rgb(0,162,224);
      font-weight: 600;
      font-style: normal;
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-size: 24px;
      letter-spacing: 2px;
      text-transform: none;
      line-height: 1.2em;
    }
    .box-info {
      margin-bottom: 3.8em;
    }
    .box-info-item {
      margin-top: 3.5em;
    }
    h3 {
      margin: 0.1em 0 .75em;
      white-space: pre-wrap;
      color:  #000;
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 600;
      font-style: normal;
      font-size: 17px;
      letter-spacing: 2px;
      text-transform: uppercase;
      line-height: 1.3em;
    }
    p,ol li,a {
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 300;
      font-style: normal;
      //text-align: center;
      font-size: 17px;
      letter-spacing: 2px;
      line-height: 1.6em;
      text-align: left;
      text-indent: 2em;
      color: #000;
      white-space: pre-wrap;
      margin-bottom: .68em;
    }
    ul {
      margin-left: 36px;
      li {
        margin: 0.5em 0;
      }
    }
    .box-jump {
      margin: 2em 0;
      .jump-buttons {
        width: 150px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #111;
        border: 2px solid #111;
        font-size: 16px;
        border-radius: 2px;
        margin: 2.6em auto;
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }
  }
}
.desc-wrapper {
  animation: feature-text-anim .75s ease-in-out;
  top: 52%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-42%);
  width: 100%;
  max-width: 956px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  p{
    white-space: pre-wrap;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 600;
    font-style: normal;
    font-size: 56px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.3em;
    color: #fff;
    margin: 20px auto;
    -webkit-transform: translatez(0);
  }
}
/deep/ .el-form--label-top .el-form-item__label {
  font-size: 20px;
  white-space: pre-wrap;
  color: #000;
  font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
  font-weight: 400;
}
/deep/ .el-checkbox {

  display: block;

  .el-checkbox__label {
    font-size: 15px;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    white-space: pre-wrap;
    color: #000;
  }
}
/deep/ .el-radio {

  display: block;
  margin-bottom: 20px;

  .el-radio__label {
    font-size: 15px;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    white-space: pre-wrap;
    color: #000;
  }
}
.imgLists-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
    width: 48.5%;

  }
}
/deep/ .el-button--medium{
  font-size: 20px;
  font-family:  Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;;
  background: #000;
  padding: 20px 38px;
}
/deep/ .el-divider--horizontal {
  margin: 33px 0;
}
</style>

