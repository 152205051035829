<template xmlns="http://www.w3.org/1999/html">
  <div id="Home">
    <el-row class="rowPos">
      <el-col :span="24" class="colPos">
        <!--        <div class="is-background"></div>-->
        <img src="@/assets/img/bg8.jpg" style="width: 100%;height: auto;">
        <div class="desc-wrapper">
          <p>{{descInfo}}</p>
        </div>
        <!--            <el-carousel height="600px" :interval=5000 style="z-index: -1;">-->
        <!--              <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">-->
        <!--&lt;!&ndash;            <el-carousel-item v-for="(item, index) in imgs" :key="index">&ndash;&gt;-->
        <!--&lt;!&ndash;                <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">&ndash;&gt;-->
        <!--&lt;!&ndash;            </el-carousel-item>&ndash;&gt;-->
        <!--        </el-carousel>-->
      </el-col>
      <!--      <div style="height: 600px;background-color: rgba(33,27,23,.21);"></div>-->
    </el-row>
    <div class="main-content">
      <div style="max-width: 1020px;width: auto;padding:0 28px !important;margin: auto">
        <div class="main-content-infos sqs-layout">
          <p>SoMe音乐学校，是一家致力于提供高质量 、智能化音乐教育服务的机构 。自2022年在新加坡设立东南亚总部以来， 已经赢得了广泛的认可与赞誉 。</p>
          <p>我们汇聚了一支由中国与新加坡资深音乐教育专家组成的精英教师团队 ，每位老师不仅拥有丰富的教学经验和专业资质。 教学模式采用线上和线下相结合的方式 ，培养学生的音乐兴趣和能力，为学生的音乐生涯和未来发展打下坚实的基础 。</p>
          <p>SoMe音乐学校举办数次学生音乐会，户外音乐活动营，新加坡国际学校国际周演出承办，协办中国驻新加坡大使馆新年音乐会。</p>
          <!--          <h2>-->
          <!--            Here are the heroes on our team who make Joy Music Studio a GREAT place to learn music and realise musical dreams.            </h2>-->
          <div class="main-content-infos-introd">
            <div class="introd-item">
              <div class="introd-item2">
                <div class="introd-img">
                  <img src="@/assets/img/xiaozhang1.jpg">
                </div>
                <div class="introd-item-title2">Emily WANG</div>
                <div class="introd-item-info">
                  <p>Emily Wang ，王晨帆，旅英钢琴教育家，施坦威荣誉教师，新加坡教育局MOE认证教师，Emily Wang ，王晨帆，旅英钢琴教育家，施坦威荣誉教师，新加坡教育局MOE认证教师，咨询发展有限公司董事长， 国际青少年领袖发展基金会音乐教育主任，新加坡青少年与儿童教育协会会员， 国际音乐家协会会员，10年大学钢琴系任教，中国钢琴家教师协会会员，湖南省音乐家协会会员，湖南省音乐家协会钢琴专业委员会会员，中国音乐家学会社会艺术水平考级考官，湖南省普通高校招生音乐类专业考试评委，施坦威国际全国青少年钢琴比赛评委，央音全国青少年艺术展演评委，亚洲国际艺术大赛艺术顾问兼评委，爱乐国际音乐大赛评委。
                  </p>
                  <p>本科毕业于天津音乐学院，本科期间获得RNCM等多所学校的录取通知书，多种考虑后，选择硕士研究生就读于英国曼彻斯特大学，获得硕士学位。留学期间，曾组织中国留学生驻英新年音乐会，在Whitworth Art Gallery举办个人新作品音乐会，并邀请英国当地知名演奏家演奏作品。在长沙师范学院任教十年期间，负责音乐舞蹈学院和学前教育学院的教学工作，编写教学大纲和人才培养方案，授课范围涵盖音乐理论、钢琴演奏、即兴伴奏等，授课超过 3000 名学生，研究专长为儿童音乐教育，发表论文20余篇，主持2项省级课题，1项校级重点课题，2项已发表的专利，作为成员参与4项省部级课题，指导学生成功申报课题1项。</p>
                  <p>教学期间指导学生参赛获得全球国际音乐家协会提名优秀指导教师、亚洲国际艺术大赛优秀指导教师、香港国际钢琴邀请赛英才导师奖，墨尔本国际音乐节优秀指导教师，爱乐国际音乐大赛优秀导师奖，莫扎特纪念奖国际钢琴公开赛优秀指导教师奖等等。
                  </p>
                </div>
                <h2>创始人部分证书</h2>
                <div style="display: flex;justify-content: space-between;flex-wrap: wrap;width: 82%" class="imgLists3">
                  <img src="@/assets/img/z1.png" style="width: 60%" class="imgs1">
                  <img src="@/assets/img/z2.png" style="width: 38%" class="imgs1">
                </div>
                <h2>创始人活动照片</h2>
                <div class="imgLists">
                  <!--            <div v-for="(item,i) in imgList" :key="i" class="imgLists-item">-->
                  <div class="imgLists-item">
                    <img src="@/assets/img/z6.png">
                    <img src="@/assets/img/z7.png">
                  </div>
                  <div class="imgLists-item">
                    <img src="@/assets/img/z4.png" >
                    <img src="@/assets/img/z3.jpg" >
                  </div>
                  <div class="imgLists-item">
                    <img src="@/assets/img/z8.png" >
                  </div>
                  <!--              <p class="p1">{{item.name}}</p>-->
                  <!--            </div>-->
                </div>

              </div>

            </div>

          </div>
          <div class="main-content-infos-introd" style="width: 82%" id="elementId1">
            <div class="introd-item">
              <div class="introd-item-title"><h1>老师介绍</h1></div>
              <div class="introd-item2">
                <div class="introd-img2">
                  <img src="@/assets/img/jiaoshi1.png" >
                </div>
                <div class="introd-item-title2">张驰</div>
                <div class="introd-item-info2">
                  <p><strong>小提琴家，</strong>陕西西安人。毕业于
                    新加坡杨秀桃国立音乐学院。他六岁起随秦孝安开始学习小提琴，在2004年张驰考入西安音乐学院跟随著名小提琴教育家教授李启华学习，在校期间，曾多次获得全国及陕西省器乐大赛一等奖，在2006 年，他又随上海音乐学院小提琴家教育家何弦继续深造学习，同年他考入广州国际音乐节，与众多国际知名演奏家和乐团同台演出，包括桑特尔·茱莉叶，诹访内晶子，中国爱乐交响乐团。               </p>
                 <p>在2009年，张驰以全额奖学金考入新加坡杨秀桃国立音乐学院，并在小提琴系教研主任左军教授指导下学习，张驰有着非常丰富的表演经验，在校期间与世界著名三大男高音卡雷拉 斯在新加坡滨海艺术中心同台演出，2010年，他被台湾新竹教育大学邀请演。2011年，他获得新加坡杨秀桃小提琴协奏曲比赛最佳演奏奖. 2014 年起，张驰担任新加坡少儿交响乐团小提琴主任，并获得终身首席职称。</p>
                </div>

              </div>
              <div class="introd-item2">
                <div class="introd-img2" >
                  <img src="@/assets/img/jiaoshi2.png" style="object-position: 32% 32.40276909221658%;">
                </div>
                <div class="introd-item-title2">杜佳瑶</div>
                <div class="introd-item-info2">
                  <p><strong>乐理课讲师，</strong>湖南永州人，从小学习音乐，在中学期间两次获得湖南省“三独”比赛铜奖，2009年毕业于湖南师范大学音乐学专业，2012年参加金铁霖中国声乐高级研修班进修，2013年获得重庆大学师资管理硕士学位，2021年获得讲师职称，毕业15年一直在高校任职并有丰富的声乐、乐理、视唱练耳教学经验。发表期刊论文8篇，加强以文育人，以艺通心，2021年指导《山丹丹花开红艳艳》舞剧获得广东省“立志、修身、博学、报国”主题教育活动二等奖；指导微视频《萤火燎旧厝》荣获佛山市南海区“多彩乡村  学史奋进”主题教育活动二等奖，在乐理教学上有丰富的经验，善于用科学教学方法，整合教学内容，达到高效、高质量教学模式，能教授各年龄学生，让学生用通俗易懂的方式轻松学乐理。                </p>
                </div>

              </div>
              <div class="introd-item2">
                <div class="introd-img2">
                  <img src="@/assets/img/jiaoshi3.jpg" style="object-position: 100% 100%;margin-top: 2px">
                </div>
                <div class="introd-item-title2">邹祯</div>
                <div class="introd-item-info2">
                  <p><strong>钢琴陪练老师，</strong>师从创始人Emily老师。学习期间以优异的成绩考取多项证书如钢琴考级、器乐艺术指导等；积极参与各类钢琴比赛如美国联邦钢琴公开赛等，成绩优异；多次参加教学比赛，教学成果显著。</p>
                  <p>作为一位热爱音乐、深耕钢琴教育多年的陪练老师，我秉持“音乐启智，快乐成长”的教育理念，坚信每位学生都有独特的音乐潜能。课堂上，我注重倾听每个音符背后的故事，根据学生的个性与兴趣定制教学计划，让每一节课程都充满欢笑与探索。我的目标不仅是教授技巧，更希冀激发学生对音乐的热爱与创造力，共同奏响生命的华章。
                  </p>
                  <p>在这里，没有枯燥乏味，只有琴键跳跃的乐章与心与心的交流。让我们一起，用音乐绘梦，用心感受每一个音符的魅力！</p>
                </div>

              </div>
              <div class="introd-item2">
                <div class="introd-img2" style="height: 186px">
                  <img src="@/assets/img/jiaoshi4.jpg" style="object-position: 30% 100%;margin-top: 28px">
                </div>
                <div class="introd-item-title2">罗寒月</div>
                <div class="introd-item-info2">
                  <p><strong>钢琴陪练老师，</strong>师从创始人Emily老师，曾在英皇考级中部分曲目拿到满分(30/30)成绩，并且多次参加国内外钢琴大赛:</p>
                  <ol>
                    <li>2019 年 5 月荣获第五届墨尔本国际音乐节中国预赛区青年组二等奖(六手联弹); </li>
                    <li>2020 年 6 月荣获第三届美国联邦钢琴公开赛湖南省赛区高校组二等奖; </li>
                    <li>2023 年 4 月荣获第七届 KAWAI 亚洲钢琴大赛长沙赛区考级组特等奖; </li>
                    <li>2023 年 5 月荣获第七届”央音“青少年艺术展演长沙地区青年组金奖;</li>
                    <li>2023 年 6 月荣获 2023 Music Singapore International Piano&Violin Competition 英皇考级组二等奖。 </li>
                  </ol>
                  <p>在教育教学中，亲和力强，严谨细心，秉持“兴趣是最好的老师”的教学原则，帮助学生打下扎实的基本功的 同时，注重学生音乐兴趣及综合素养的培养。其次，善于调动学生的学习积极性，引导学生自主思考，帮助学生养成良好的学习方法与练琴习惯;在课堂中,尊重学生想法及家长意见，认真倾听学生演奏，帮助学生在轻松的课堂氛围中逐步解决学习难题.</p>
                </div>

              </div>
            </div>
          </div>
          <div class="main-content-infos-introd">
            <div class="introd-item">
              <div class="introd-item-title"><h1>校区介绍</h1></div>
              <div class="introd-item2">
                <div class="introd-item-info2">
                  <p>宽敞的教室，舒适的等候空间，波士顿三角钢琴的小型录音空间+10-12人的分享会空间。3间共享琴房，I人最佳练琴空间 </p>
                </div>
                <h2>校区外部</h2>

                  <img src="@/assets/img/back1.jpg" style="width: 100%">
                <div style="display: flex;flex-wrap: wrap;justify-content: space-between;margin-top: 15px">
                  <img src="@/assets/img/waibu1.jpg" style="width: 32%;margin-bottom: 16px">
                  <img src="@/assets/img/waibu2.jpg" style="width: 32%;margin-bottom: 16px">
                  <img src="@/assets/img/waibu3.jpg" style="width: 32%;margin-bottom: 16px">
                </div>
                <h2>校区内部</h2>
                <div style="display: flex;flex-wrap: wrap;justify-content: space-between;">
                  <img src="@/assets/img/neibu2.jpg" style="width: 32%;margin-bottom: 16px">
                  <img src="@/assets/img/neibu1.jpg" style="width: 32%;margin-bottom: 16px">
                  <img src="@/assets/img/neibu3.jpg" style="width: 32%;margin-bottom: 16px">
                </div>
                <div style="display: flex;flex-wrap: wrap;justify-content: space-between;">
                  <img src="@/assets/img/neibu4.jpg" style="width: 32%;margin-bottom: 16px">
                  <img src="@/assets/img/neibu6.jpg" style="width: 32%;margin-bottom: 16px">
                  <img src="@/assets/img/neibu5.jpg" style="width: 32%;margin-bottom: 16px">
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "HomeIndex",
  props: {
    ind: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inds: this.$route.query.ind || '',
      imageUrl: '',
      descInfo: '关于SoMe',
      acctivateName: "small",
      aiTitleSmallList: [],
      activeIndex: 0,
      counts: 0,
      flag: false,
      carouselItems1:"",
      carouselItems2: "",
      applyObj: {},
      currentPage: 1,
      pageSize: 2,
      type: 10,
      imgs: [],
      homepageSceneList:[],
      background:[],

    }
  },
  methods: {
    imgPath() {
      return 'http://hjjai.com:6789/commonImg/'
    },
    steCarou(nub){
      this.carouselItems1 = this.carouselItems[nub].title;
      this.carouselItems2 = this.carouselItems[nub].content;
      console.log(this.carouselItems[nub].content);
      console.log(this.carouselItems[nub].title);

    },
    changeIn(val){
      // console.log(val);
      this.steCarou(val)
    },
    boxFiveDemo(event) {
      let obj = this.boxFive.filter((item) => {
        return item.id.toString() === event.target.id;
      });
      this.applyObj = obj[0];
      this.boxFive[event.target.id-1].Icon=true
    },

  },
   mounted() {
    //  this.flag = false
    //  console.log('1000',this.inds)
    // if(this.inds && !this.flag) {
    //
    //     var element = document.getElementById('elementId1');
    //   var distance = element.offsetTop;
    //   // var distance = parseFloat(style.getPropertyValue('top'));
    //
    //      console.log('distance',distance)
    //     window.scrollTo({
    //       top: distance+1600,
    //       left: 0,
    //       behavior: 'smooth' // 可选，平滑滚动效果
    //     });
    //     this.$forceUpdate()
    // }
  },
   activated() {

       window.scrollTo({
         top: 0,
         left: 0,
         behavior: 'smooth' // 可选，平滑滚动效果
       });

     this.$forceUpdate()
     // console.log('000',this.inds)
    if(this.inds) {

        var element = document.getElementById('elementId1');
      var distance = element.offsetTop;
      // var distance = parseFloat(style.getPropertyValue('top'));

         console.log('distance',distance)
      if(this.counts==0) {
        window.scrollTo({
          top: distance+680,
          left: 0,
          behavior: 'smooth' // 可选，平滑滚动效果
        });
      }else {
        window.scrollTo({
          top: distance-110,
          left: 0,
          behavior: 'smooth' // 可选，平滑滚动效果
        });
      }

         this.counts++
        this.$forceUpdate()
    }
  },
  components: {
  },
  watch: {
    inds(newVal) {
      console.log('----newVal',newVal)
    }
  },
  computed: {
  }
}
</script>

<style lang="less" scoped>
.colPos {
  position: relative !important;
  height: 600px;
  overflow: hidden;
  .is-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(33, 27, 23, .21);
  }
  img {
    //position: relative;
    //
    //top: -260px;
    margin-top: -13%;
  }

}

h1 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 30px;

  span {
    margin-right: 20px;
  }

}
p {
  white-space: pre-wrap;
  font-family: Microsoft YaHei;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 3px;
  margin-bottom: .72em;
  text-transform: none;
  line-height: 1.6em;
  color: #000;
  font-size: 17px;
}
.main-content {

  background: #ffffff;
  margin:0px auto 30px;
  padding-top: 10px;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: center;
  .imgLists3 {
    margin-top: 12px;
  }
  .imgLists {
    display: flex;
    margin-top: 12px;
    //align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .imgLists-item {
      width: 31%;
      img {
        width: 100%;
        margin-bottom: .6em;
      }
      .p1 {
        white-space: pre-wrap;
        font-family: Microsoft YaHei;
        font-weight: 300;
        font-style: normal;
        letter-spacing: 3px;
        text-transform: none;
        line-height: 1.2em;
        color: #000;
        font-size: 18px;
      }
      .p2 {
        color: #000;
        white-space: pre-wrap;
        font-family: Microsoft YaHei;
        font-weight: 300;
        font-style: normal;
        letter-spacing: 3px;
        text-transform: none;
        line-height: 1.6em;
        font-size: 18px;
      }
    }
    .imgLists-item2 {
      width: 48%;
    }
  }
  &-infos {
    padding: 28px 0;
    width: 88%;
    margin: auto;
    box-sizing: border-box;
    &-introd {
      margin-top: 3.88em;
      .introd-item {
        .introd-item-title {
          h1 {
            margin: 2.98em 0 1.1em;
            font-family: Microsoft YaHei;
            color: rgb(0,162,224);
            font-weight: 600;
            text-align: center;
            font-style: normal;
            font-size: 30px;
            letter-spacing: 4px;
            text-transform: none;
            line-height: 1.3em;
            white-space: pre-wrap;
          }
        }
        .introd-item2 {
          position: relative;
          z-index: 11;
          margin-bottom: 3.82em;
          .introd-item-title2 {
            color: rgb(0,162,224);
            font-family: Microsoft YaHei;
            font-weight: 600;
            font-style: normal;
            font-size: 18px;
            letter-spacing: 3px;
            text-transform: uppercase;
            line-height: 1.3em;
            white-space: pre-wrap;
            margin-bottom: .7em;
          }
          .introd-item-info {
            p {
              margin-bottom: .72em;
              white-space: pre-wrap;
              font-family: Microsoft YaHei;
              font-weight: 300;
              font-style: normal;
              font-size: 17px;
              letter-spacing: 3px;
              line-height: 1.6em;
              color: #000;
            }
          }
          .introd-item-info2 {
            p {
              margin-bottom: .72em;
              white-space: pre-wrap;
              font-family: Microsoft YaHei;
              font-weight: 300;
              font-style: normal;
              font-size: 18px;
              letter-spacing: 3px;
              line-height: 1.6em;
              color: #000;
            }
            ol {
              margin-left: 36px;
            }
            li {
              margin-bottom: .5em;
              white-space: pre-wrap;
              font-family: Microsoft YaHei;
              font-weight: 300;
              font-style: normal;
              font-size: 16px;
              letter-spacing: 2px;
              line-height: 1.3em;
              color: #000;
            }
          }
        }
        .introd-img {
          float: right;
          position: relative;
          z-index: 11;
          //padding: 30px;
          box-sizing: border-box;
          width: 38%;
          height: 290px;
          //overflow: hidden;
          /* clip-path: url(#yui_3_17_2_1_1680071662615_128003); */
          //-webkit-clip-path: url(#yui_3_17_2_1_1680071662615_128003);
          img {
            margin: 20px 24px;
            display: block;
            object-fit: cover;
            width: 80%;
            height: 270px;
            border-radius: 100%;
            object-position: 100% 12.40276909221658%;
            position: absolute;
            top: 0;
            left: 0;
            max-width: none;
          }
        }
        .introd-img2 {
          float: right;
          position: relative;
          z-index: 11;
          //padding: 30px;
          box-sizing: border-box;
          width: 36%;
          height: 210px;
          //overflow: hidden;
          /* clip-path: url(#yui_3_17_2_1_1680071662615_128003); */
          //-webkit-clip-path: url(#yui_3_17_2_1_1680071662615_128003);
          img {
            margin: 20px 24px 0px;
            display: block;
            object-fit: cover;
            width: 78%;
            height: 202px;
            border-radius: 100%;
            object-position: 100% 32.40276909221658%;
            position: absolute;
            top: 0;
            left: 0;
            max-width: none;
          }
        }

      }
    }
    h2 {
      color: #000;
      margin: 1.56em 0 .5em;
      font-family:Microsoft YaHei;
      font-weight:600;
      font-style: normal;
      font-size: 24px;
      letter-spacing: 4px;
      text-transform: none;
      line-height: 1.5em;
      //text-align: center;
      white-space: pre-wrap;
    }
  }

}
.main-content-infos-introd .introd-item2:nth-child(even) .introd-img2{
  float: left;
}
.desc-wrapper {
  animation: feature-text-anim .75s ease-in-out;
  top: 52%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-42%);
  width: 100%;
  max-width: 956px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  p {
    white-space: pre-wrap;
    font-family: Microsoft YaHei;
    font-weight: 300;
    font-style: normal;
    font-size: 56px;
    letter-spacing: 4px;
    text-transform: uppercase;
    line-height: 1.3em;
    color: #fff;
    margin: 20px auto;
    -webkit-transform: translatez(0);
  }
}
</style>
