import { render, staticRenderFns } from "./TrialLessons.vue?vue&type=template&id=04d5e2a2&scoped=true"
import script from "./TrialLessons.vue?vue&type=script&lang=js"
export * from "./TrialLessons.vue?vue&type=script&lang=js"
import style0 from "./TrialLessons.vue?vue&type=style&index=0&id=04d5e2a2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04d5e2a2",
  null
  
)

export default component.exports