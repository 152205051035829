<template>
    <div class="bottomFooter mainColor">
       <div class="bottomFooter-content">
         <div class="site-info">
           <div class="site-bottom2">
             <i class="el-icon-phone-outline" style="font-size: 22px;vertical-align: middle"></i>: +65 80398964
           </div>
           <div style="margin: .3em 0"  class="site-bottom2">
             <i class="el-icon-message" style="font-size: 22px;vertical-align: middle"></i>: somemusicschool@gmail.com
           </div>
           <div  class="site-bottom">
             <i class="el-icon-location-outline" style="font-size: 24px;vertical-align: middle"></i>: 66 E Coast Rd, B1-02 the Flow, SG 428778
           </div>
         </div>

         <div class="site-bottom">
           ©Copyright 2024 SingaporeOverseaMusicEducationPte.Ltd
         </div>
       </div>
    </div>
</template>

<script>
export default {
    data() {
        return {


        }
    },
   methods:{
     goFun1() {
       this.$router.push({path: '/AboutUs'})
     },
     goFun2() {
       // this.$router.push({path: '/TrialLessons'})
       console.log('-----',window.location.href)
       // window.open()
     }
   }
}

</script>

<style lang="less" scoped>
.bottomFooter{
    text-align: center;
    width:100%;
     //background-color: #0d82a5;
    color: rgba(255, 255, 255, .4);
  &-content {
    padding: 64px 32px;
    box-sizing: border-box;
    max-width: 1020px;
    width: auto;
    margin: auto;
    .site-info {
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    letter-spacing: 2px;
    //text-transform: uppercase;
    text-decoration: none;
      color: rgba(255, 255, 255, .9);
      line-height: 1.8em;
  }
    .site-bottom2 {
      white-space: pre-wrap !important;
      word-break: break-word;
      //display: flex;
      //justify-content: center;
      color: #eee;
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 300;
      font-style: normal;
      font-size: 18px;
      padding: 2px;
      box-sizing: border-box;
      letter-spacing: 2px;
      line-height: 1.6em;
    }
    .site-bottom {
      white-space: pre-wrap !important;
      word-break: break-word;
      color: rgba(255, 255, 255, .4);
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 300;
      font-style: normal;
      font-size: 17px;
      padding: 16px;
      box-sizing: border-box;
      letter-spacing: 1px;
      line-height: 1.6em;
    }
  }
}
</style>
