<template>
  <div id="Home">
    <el-row class="rowPos">
      <el-col :span="24" class="colPos">
<!--        <div class="is-background"></div>-->
<!--        <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: auto;">-->
        <div class="desc-wrapper">
          <p>{{$store.state.curIndex==1 ? 'Our School Calendar' : '我们的校历'}}</p>
        </div>
        <!--            <el-carousel height="600px" :interval=5000 style="z-index: -1;">-->
        <!--              <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">-->
        <!--&lt;!&ndash;            <el-carousel-item v-for="(item, index) in imgs" :key="index">&ndash;&gt;-->
        <!--&lt;!&ndash;                <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">&ndash;&gt;-->
        <!--&lt;!&ndash;            </el-carousel-item>&ndash;&gt;-->
        <!--        </el-carousel>-->
      </el-col>

    </el-row>
    <el-row class="main-content">
    <div class="main-content-box">
    <div class="box-info">
     <h1>{{$store.state.curIndex==1?'Our School Calendar':'我们的校历'}}</h1>
      <p>
        {{$store.state.curIndex==1?'At SoMe Music School, we believe that regular classes provide students with valuable time to interact with their teachers:':'在SoMe Music School，我们相信常规课程可以给学生提供与老师互动的时间：'}}
      </p>
      <ul>
        <li>{{$store.state.curIndex==1?'Achieving good progress in music education':'在音乐教育上取得良好的进步'}}</li>
        <li>{{$store.state.curIndex==1?'Preparing for recitals and exams':'为他们准备演奏会和考试'}}</li>
        <li>{{$store.state.curIndex==1?'Inspiring them to reach higher levels of learning':'激励他们去更高层次的学习'}}</li>
      </ul>
      <p>{{$store.state.curIndex==1?'That\'s why our school calendar offers the optimal number of lessons each year, ensuring consistent class time, breaks, and practice':'这就是为什么我们的学校日历每年为学生提供最佳的课程数量，以保持一致的上课时间，休息和练习'}}</p>
    </div>
      <div class="box-calendar">
        <img src="@/assets/img/xiaoli.jpeg">
      </div>
<!--      <div class="box-content">-->
<!--        <h3>今天就开始你的音乐之旅吧！</h3>-->
<!--        <p>You can start on any date because we will pro-rate your first month’s fee to reflect the actual number of lessons for the month.</p>-->
<!--        <p>One-time registration fee (non-refundable): $28</p>-->
<!--        <p>New student deposit (refundable): $128</p>-->
<!--      </div>-->
      <div class="box-content">
        <h3>{{$store.state.curIndex==1?'ATTENDANCE & RESCHEDULING LESSONS':'出席和重新安排课程'}}</h3>
        <p>{{$store.state.curIndex==1?'We understand the busy schedules of our students, and that unexpected events sometimes require lessons to be rescheduled.':'我们理解学生们繁忙的日程安排，有时突发事件需要重新安排课程。'}}</p>
        <p>{{$store.state.curIndex==1?'THANK YOU for notifying us':'感谢您在重新安排课程前'}}<strong>{{$store.state.curIndex==1?' at least 24 hours in advance ':'至少24小时'}}</strong>{{$store.state.curIndex==1?'  before rescheduling a lesson.We appreciate your consideration.':'通知我们，以方便我们通知任课老师。'}}</p>
        <p>{{$store.state.curIndex==1?'Our fantastic teachers are committed to delivering lessons as scheduled, and they will do their best to reschedule missed lessons to another time slot whenever possible. We will do our best to arrange the same teacher for the make-up class, but we cannot guarantee it will be the same teacher.':'我们优秀的老师保证按时上课，同时他们也会尽最大努力在可能的情况下重新安排错过的课程。我们会尽力安排同样的老师补课，但是不保证会同一老师补课'}}</p>
      </div>
      <div class="box-content">
        <h3>{{$store.state.curIndex==1?'ATTENDANCE POLICIES':'出勤政策'}}</h3>
        <p v-if="$store.state.curIndex==2">入学后，我们的管理团队将指导您了解我们的条款和条件，包括学生出勤，缺课和重新安排课程的政策。请在<strong style="cursor: pointer" @click="goFun1">这里</strong>查看我们学校规定。</p>
        <p v-else>Upon enrolment, each our administration team will guide you through our terms & conditions, including policies on student attendance,missed lessons, and rescheduling of lessons.&nbsp;  Please see our school policies<strong style="cursor: pointer" @click="goFun1"> here.</strong></p>
        <p>{{$store.state.curIndex==1?'To learn more about these our school policies, please feel free to contact us at +65 8039-8964 or email us at somemusicschool@gmail.com.':'要了解更多关于学校规定的信息，请随时联系我们，电话：+65 80398964或发邮件至somemusicschool@gmail.com。'}}</p>
      </div>
      <div class="box-content">
        <h3>{{$store.state.curIndex==1?'WITHDRAWAL':'退出课程'}}</h3>
        <p>{{$store.state.curIndex==1?'Please note: One-month (30 days) advance notice is required to withdraw from any of our music courses.Please complete a ':'请注意：退课需要提前一个月（30天）通知，请尽快填写'}}<strong style="cursor: pointer" @click="goFun2">{{$store.state.curIndex==1?'Withdrawal Form':'退课请求表'}}</strong>，{{$store.state.curIndex==1?' as soon as possible to confirm the student\'s withdrawal from our school.':'以确认学生退出课程，休学。'}}</p>
      </div>
    </div>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "HomeIndex",
  data() {
    return {
      imageUrl: '',
      descInfo: '校历安排',
      descInfoB: 'Our School Calendar',
      acctivateName: "small",
      aiTitleSmallList: [],
      activeIndex: 0,
      carouselItems1:"",
      carouselItems2: "",
      applyObj: {},
      currentPage: 1,
      pageSize: 2,
      type: 10,
      imgs: [],
      homepageSceneList:[],
      background:[],
f
    }
  },
  props: {},
  methods: {
    imgPath() {
      return 'http://hjjai.com:6789/commonImg/'
    },
    goFun1(){
      this.$store.commit('setIndex','4-1')
    this.$router.push({path:'/SchoolPolicy'})
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth' // 可选，平滑滚动效果
      });
    },
    goFun2(){
      this.$store.commit('setIndex','4-3')
    this.$router.push({path:'/FormDownload'})
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth' // 可选，平滑滚动效果
      });
    },
    changeIn(val){
      // console.log(val);
      this.steCarou(val)
    },
    boxFiveDemo(event) {
      let obj = this.boxFive.filter((item) => {
        return item.id.toString() === event.target.id;
      });
      this.applyObj = obj[0];
      this.boxFive[event.target.id-1].Icon=true
    },

  },
  async mounted() {
    this.applyObj = this.boxFive[0];
    // window.addEventListener("load", () => {
    //     this.$store.commit("setIndex", "1")
    // })
    const result = await axios.get('http://hjjai.com:6789/img/getImgByType', {
      params: {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        type: this.type
      }
    })
    this.imgs = result.data.data.data
    // console.log(this.imgs)
    // 获取首页场景
    // let homepageScene = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 3,
    //         type: 14
    //     }
    // })
    // this.homepageSceneList = homepageScene.data.data.data
    // let background = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 1,
    //         type: 12
    //     }
    // })
    // this.backgroundList = background.data.data.data
    console.log(this.homepageSceneList);
  },
  components: {
  },
  computed: {
    currentContent(){
      return this.carouselItems[this.activeIndex].content;
    }
  }
}
</script>

<style lang="less" scoped>
.colPos {
  position: relative !important;
  height: 600px;
  overflow: hidden;
  background-color: #1f1f1f;
  .is-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(33, 27, 23, .21);
  }
  img {
    //position: relative;
    //
    //top: -260px;
    margin-top: -13%;
  }

}
.main-content-box {
  max-width: 1020px;
  width: auto;
  padding:0 28px !important;
  margin: auto;
  .box-info {
    margin-top: 2.6em;
  }
  .box-calendar {
    margin: 2.2em 0;
   img {
     width: 100%;
     height: auto;
   }
  }
  h1 {
    margin: 1em 0 .92em;
    white-space: pre-wrap;
    text-align: center;
    color:  rgb(0,162,224);
    font-weight: bold;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
  font-style: normal;
  font-size: 30px;
  letter-spacing: 2px;
  text-transform: none;
  line-height: 1.2em;
}
  h3 {
    white-space: pre-wrap;
    margin: 3.2em 0 .9em;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.3em;
    color:  rgb(0,162,224);
  }
  p,ul li {
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 300;
    font-style: normal;
    font-size: 17px;
    letter-spacing: 2px;
    line-height: 1.6em;
    color: #000;
    white-space: pre-wrap;
    margin-bottom: .8em;
  }
  ul {
    margin-left: 36px;
   li {
     margin: 0.5em 0;
   }
  }
}
.main-content {
  background: #ffffff;
  padding-top: 30px;
  margin-bottom: 60px;
  .box-content {
    margin-top: 20px;
    margin-bottom: 45px;
  }
}
.desc-wrapper {
  animation: feature-text-anim .75s ease-in-out;
  top: 52%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-42%);
  width: 100%;
  max-width: 956px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  p {
    white-space: pre-wrap;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 600;
    font-style: normal;
    font-size: 56px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.3em;
    color: #fff;
    margin: 20px auto;
    -webkit-transform: translatez(0);
  }
}
</style>
