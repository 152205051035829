<template>
  <div id="Home">
    <el-row class="rowPos">
      <el-col :span="24" class="colPos">
<!--        <div class="is-background"></div>-->
        <!--        <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: auto;">-->
        <div class="desc-wrapper">
          <p>{{$store.state.curIndex==1 ? descInfoB:descInfo}}</p>
        </div>
        <!--            <el-carousel height="600px" :interval=5000 style="z-index: -1;">-->
        <!--              <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">-->
        <!--&lt;!&ndash;            <el-carousel-item v-for="(item, index) in imgs" :key="index">&ndash;&gt;-->
        <!--&lt;!&ndash;                <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">&ndash;&gt;-->
        <!--&lt;!&ndash;            </el-carousel-item>&ndash;&gt;-->
        <!--        </el-carousel>-->
      </el-col>

    </el-row>
    <el-row class="main-content">
      <div class="main-content-box">
        <div class="box-info">
          <h1 style="text-align: left">{{$store.state.curIndex==1?'Form':'表格'}}</h1>
          <p>{{$store.state.curIndex==1?'Appeal Request Form':'申诉请求表'}}: <span class="strong" @click="clickFun(1)">{{$store.state.curIndex==1?'click here':'点击此处'}}</span></p>
          <p>{{$store.state.curIndex==1?'Temporary Suspension Form':'临时停课申请表'}}: <span class="strong" @click="clickFun(2)">{{$store.state.curIndex==1?'click here':'点击此处'}}</span></p>
          <p>{{$store.state.curIndex==1?'Withdrawal Form':'退课请求表'}}: <span class="strong" @click="clickFun(3)">{{$store.state.curIndex==1?'click here':'点击此处'}}</span></p>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "HomeIndex",
  data() {
    return {
      imageUrl: '',
      descInfo: '表格下载',
      descInfoB: 'Student Forms',
      acctivateName: "small",
      aiTitleSmallList: [],
      activeIndex: 0,
      carouselItems1:"",
      carouselItems2: "",
      applyObj: {},
      currentPage: 1,
      pageSize: 2,
      type: 10,
      imgs: [],
      homepageSceneList:[],
      background:[],

    }
  },
  props: {},
  methods: {
    clickFun(type) {
      let link = ''
      let name = ''
      if (type === 1) {
        link = 'https://forms.gle/k174cjxvgfpppUu6A'
        name = '申诉请求表'
      }
      else if (type === 2) {
        link = 'https://forms.gle/KeUxYdweVYbBATx77'
        name = '临时停课申请表'
      } else if (type === 3) {
        link = 'https://forms.gle/kBzRuCMfEHDDF9mYA'
        name = '退课请求表'
      }

// 创建a标签
      let a = document.createElement('a')
// 定义下载名称
      a.download = name
// 隐藏标签
      a.style.display = 'none'
// 设置文件路径
      a.href = link
      a.target = '_blank'
// 将创建的标签插入dom
      document.body.appendChild(a)
// 点击标签，执行下载
      a.click()
// 将标签从dom移除
      document.body.removeChild(a)
    }

  },
  async mounted() {

    // console.log(this.imgs)
    // 获取首页场景
    // let homepageScene = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 3,
    //         type: 14
    //     }
    // })
    // this.homepageSceneList = homepageScene.data.data.data
    // let background = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 1,
    //         type: 12
    //     }
    // })
    // this.backgroundList = background.data.data.data

  },
  components: {
  },
  computed: {

  }
}
</script>

<style lang="less" scoped>
.colPos {
  position: relative !important;
  height: 600px;
  overflow: hidden;
  background-color: #1f1f1f;
  .is-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(33, 27, 23, .21);
  }
  img {
    //position: relative;
    //
    //top: -260px;
    margin-top: -13%;
  }

}
.main-content-box {
  max-width: 1020px;
  width: auto;
  padding:0 28px !important;
  margin: auto;
  .box-info {
    margin-top: 2.6em;
  }
  .box-calendar {
    margin: 2.2em 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  h1 {
    margin: 1.5em 0 1em;
    white-space: pre-wrap;
    text-align: center;
    color:  rgb(0,162,224);
    font-weight: bold;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-style: normal;
    font-size: 30px;
    letter-spacing: 3px;
    text-transform: none;
    line-height: 1.2em;
  }
  h3 {
    white-space: pre-wrap;
    margin: 3.2em 0 .9em;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.3em;
    color:  rgb(0,162,224);
  }
  p,ul li {
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 300;
    font-style: normal;
    font-size: 17px;
    letter-spacing: 2px;
    line-height: 1.6em;
    color: #000;
    white-space: pre-wrap;
    margin-bottom: .8em;
  }
  .strong {
    cursor: pointer;
    font-weight: 600;
  }
  ul {
    margin-left: 36px;
    li {
      margin: 0.5em 0;
    }
  }
}
.main-content {
  background: #ffffff;
  padding-top: 46px;
  margin-bottom: 68px;
  .box-content {
    margin-top: 20px;
    margin-bottom: 45px;
  }
}
.desc-wrapper {
  animation: feature-text-anim .75s ease-in-out;
  top: 52%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-42%);
  width: 100%;
  max-width: 956px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  p {
    white-space: pre-wrap;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 600;
    font-style: normal;
    font-size: 57px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.3em;
    color: #fff;
    margin: 20px auto;
    -webkit-transform: translatez(0);
  }
}
</style>
