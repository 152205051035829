<template>
<div id="Homes">
    <el-row class="rowPos">
        <el-col :span="24" class="colPos">
<!--          <div class="is-background"></div>-->
          <img src="@/assets/img/back1.jpg" style="width: 100%;height: auto;" class="back1">
          <div class="desc-wrapper">
            <p>{{$store.state.curIndex==1?'Joyful Learning, Lifelong lmpact':'笑容具有感染力，你的笑容价值百万'}}</p>
<!--             <div class="texts" style="font-family: HarmonyOS_Sans_SC_Light;font-size: 28px;color: #fff">“ 笑容具有感染力，你的笑容价值百万 ”</div>-->
          </div>
<!--            <el-carousel height="600px" :interval=5000 style="z-index: -1;">-->
<!--              <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">-->
<!--&lt;!&ndash;            <el-carousel-item v-for="(item, index) in imgs" :key="index">&ndash;&gt;-->
<!--&lt;!&ndash;                <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">&ndash;&gt;-->
<!--&lt;!&ndash;            </el-carousel-item>&ndash;&gt;-->
<!--        </el-carousel>-->
        </el-col>
<!--      <div style="height: 600px;background-color: rgba(33,27,23,.21);"></div>-->
    </el-row>
  <el-row class="main-content">
    <div class="main-content-box" id="Homes2">
      <div class="box-info">
        <h1> {{$store.state.curIndex==1 ? 'Where Music Brings a Smile Where Music Brings a Smile':'嗦咪悦动心声，SoMe奏响未来'}}</h1>
        <h3 style="text-align: center !important;color: rgb(0,162,224);display: block !important;">{{ $store.state.curIndex==1 ? 'Your All-in-One Music Learning Center' :'你的一站式音乐成长中心（全龄段）'}}</h3>
        <p style="text-align: center;margin-bottom: 3em">
         {{ $store.state.curIndex==1 ? 'One Hub for Musical Growth and JoyWe get everything ready, all you need to do is come in.' : '我们已为您规划好一切的音乐学习，您只需走进我们的教室。'}}
        </p>
      </div>
      <div class="box-info">

<!--       <div style="display: flex;justify-content: space-between;margin-top: 18px;flex-wrap: wrap">-->
<!--         <img class="info-img" style="width: 15.5%" src="@/assets/img/Best-in-Singapore-Badge-150x150.jpg">-->
<!--         <img class="info-img" style="width: 15.5%" src="@/assets/img/Top3MusicSchools2024(Black).png">-->
<!--         <img class="info-img" style="width: 15.5%" src="@/assets/img/Best-in-Singapore-Badge-150x150.jpg">-->
<!--         <img class="info-img" style="width: 15.5%" src="@/assets/img/Best-in-Singapore-Badge-150x150.jpg">-->
<!--         <img class="info-img" style="width: 15.5%" src="@/assets/img/Top3MusicSchools2024(Black).png">-->
<!--         <img class="info-img" style="width: 15.5%" src="@/assets/img/Top3MusicSchools2024(Black).png">-->
<!--       </div>-->
      </div>
      <div class="box-info">
        <h2 class="text-h2">{{ $store.state.curIndex==1 ? 'Beginner/Intermediate/Diploma/Adult/Practice Partner/Music Theory/Shared PracticeRooms' :'启蒙/进阶/Diploma/成人/陪练/乐理/共享琴房'}}</h2>

        <div class="imgLists">
          <div v-for="(item,i) in imgList" :key="i" class="imgLists-item" @click="checkClass(i)">
            <img :src="item.src">
            <p class="p1">{{item.name}}</p>
            <p class="p2">{{item.info}}</p>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
<!--      <div class="box-info">-->
<!--        <h2>经验丰富的师资力量</h2>-->
<!--        <div class="imgLists">-->
<!--        <div v-for="(item,i) in imgList2" :key="i" class="imgLists-item" @click="checkTeather(i)">-->
<!--          <img :src="item.src" style="object-fit: cover;object-position: 100% 60%" v-if="i!==0" class="img5">-->
<!--          <img :src="item.src" style="object-fit: cover;object-position: center" v-else class="img4 img5">-->
<!--          <p class="p1">{{item.name}}</p>-->
<!--          <p class="p2">{{item.info}}</p>-->
<!--        </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="box-info">-->
<!--        <h1>SoMe课堂</h1>-->
<!--&lt;!&ndash;        <div class="imgLists">&ndash;&gt;-->
<!--          <h2>学习目标</h2>-->
<!--          <h3>音乐欣赏：</h3>-->
<!--        <p>通过乐器的学习，不仅能够聆听不同时期的音乐作品，更能够从技法的角度深入-->
<!--          理解音乐，从旋律中探索不同时期、不同文化背景下的艺术魅力。</p>-->
<!--        <h3>情感共鸣：</h3>-->
<!--        <p>学会解析音乐的语言，捕捉每一个音符背后的情感，让音乐成为你心情的调节师。</p>-->
<!--        <h2>授课特色</h2>-->
<!--        <h3>趣味盎然的课堂：</h3>-->
<!--        <p>兴趣是最好的老师，我们将用充满乐趣的方式点燃你对音乐的热情。</p>-->
<!--        <h3>专业指导：</h3>-->
<!--        <p>经验丰富的教师将为你量身定制学习计划，确保每一步都扎实稳健。</p>-->
<!--        <h3>个性化教学：</h3>-->
<!--        <p>每个孩子都是独一无二的，我们将根据每个学生的兴趣和家庭情况设定不同的目-->
<!--          标和课程内容。</p>-->
<!--        <h2>学习过程</h2>-->
<!--        <h3>认知成长：</h3>-->
<!--        <p>音乐学习不仅能够激发大脑潜能，还能增强记忆力、专注力和创造力。</p>-->
<!--        <h3>社交技巧：</h3>-->
<!--        <p>合奏的乐趣在于合作，我们将引导孩子们学会如何与他人协作，提升沟通技巧。</p>-->
<!--        <h3>自信绽放：</h3>-->
<!--        <p>舞台上的每一次表演都是自我展示的机会，我们将鼓励孩子们勇敢地展现自我，-->
<!--          收获成就感。</p>-->
<!--        <h3>抗阻力培养：</h3>-->
<!--        <p>精通一门乐器需要持之以恒的努力，我们会帮助孩子们培养坚韧不拔的精神。</p>-->
<!--        <div class="imgLists">-->
<!--          &lt;!&ndash;            <div v-for="(item,i) in imgList" :key="i" class="imgLists-item">&ndash;&gt;-->
<!--          <div class="imgLists-item">-->
<!--            <img src="@/assets/img/k1.png">-->
<!--          </div>-->
<!--          <div class="imgLists-item">-->
<!--            <img src="@/assets/img/k2.png" >-->
<!--          </div>-->
<!--          <div class="imgLists-item">-->
<!--            <img src="@/assets/img/k3.png" >-->
<!--          </div>-->
<!--          <div class="imgLists-item">-->
<!--            <img src="@/assets/img/k4.png" >-->
<!--          </div>-->
<!--          <div class="imgLists-item">-->
<!--            <img src="@/assets/img/k5.png" >-->
<!--          </div>-->
<!--          <div class="imgLists-item">-->
<!--            <img src="@/assets/img/k6.png" >-->
<!--          </div>-->
<!--        </div>-->

<!--&lt;!&ndash;          <div v-for="(item,i) in imgList2" :key="i" class="imgLists-item">&ndash;&gt;-->
<!--&lt;!&ndash;            <img :src="item.src">&ndash;&gt;-->
<!--&lt;!&ndash;            <p class="p1">{{item.name}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;            <p class="p2">{{item.info}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->
<!--      <div class="box-info">-->
<!--        <h1>课程设计</h1>-->
<!--        <p>音乐教育不仅仅是技艺的传授，更是对孩子全面发展的投资。在这里，每个孩子都能找到属于自己的旋律，让音乐成为连接过去与未来、跨越国界的桥梁。</p>-->
<!--        <h2>Pinao钢琴课</h2>-->
<!--        <div style=""><p style="font-weight: bold;float: left">长期规划：</p><p>为您的孩子量身定制长期音乐学习计划，全面提升音乐素养。</p></div>-->
<!--        <div><p style="font-weight: bold;float: left">综合教学：</p><p>在课堂上，我们将乐理、音乐史和音乐赏析融为一体，让学生在掌握技巧的同时，领略音乐的魅力。</p></div>-->
<!--        <div style=""><p style="font-weight: bold;float: left">系统教学体系：</p><p>我们的每位学生都有团队老师进行服务，确保每一次课都能达到最佳效果。</p></div>-->
<!--        <h2>Music Theory乐理课</h2>-->
<!--        <div style="display: flex;align-items: center;flex-wrap: wrap"><p style="font-weight: bold">目标明确：</p><p>专注于用最短的课时帮助学生顺利通过ABRSM乐理等级考试。</p></div>-->
<!--        <div style="display: flex;align-items: center;flex-wrap: wrap"><p style="font-weight: bold">专业指导：</p><p>经验丰富的教师团队提供一对一辅导，确保学生在短时间内牢固掌握所需知识。</p></div>-->
<!--        <div><p style="font-weight: bold;float: left">全面准备：</p><p>结合理论教学与实践练习，加深理解，使学生能够从容应对考试。此外，我们的教研团队经过长期打磨，研发了专业的PPT教程和乐理小技巧，进一步提升学习效果。</p></div>-->
<!--        <h2>PracticePartner陪练课</h2>-->
<!--        <div style="display: flex;align-items: center;flex-wrap: wrap"><p style="font-weight: bold">专业陪练团队：</p><p>由校长亲自培养的专业教师，教师掌握儿童心理学，能够有效引导孩子。</p></div>-->
<!--        <div style="display: flex;align-items: center;flex-wrap: wrap"><p style="font-weight: bold">课堂氛围把控：</p><p>擅长营造积极、愉悦的学习环境。</p></div>-->
<!--        <div style="display: flex;align-items: center;flex-wrap: wrap"><p style="font-weight: bold">高效练琴效果：</p><p>与钢琴主课程紧密配合，确保教学连贯性。短时间内达到高效的练习效果，提升技艺。</p></div>-->
<!--      </div>-->
      <div class="box-info" style="text-align: center">
        <h2 style="margin: 2.9em 0 0.2em;">{{$store.state.curIndex==1 ? 'Testimonials':'家长评价'}}</h2>
        <div class="comment">
          <div class="comment-item">
            <div class="text1">“</div>
            <div class="text2">{{$store.state.curIndex==1?'Since Cecilia started learning piano with Emily, a teacher at SoMe Music, her technical skills in piano performance have improved significantly through various training methods. She has successfully passed her graded exams and is now able to handle various competitions and performances with ease.We are very grateful to SoMe Music and to Teacher Emily.':'Cecilia自从跟随SoMe音乐的Emily老师学习钢琴以来，通过各种训练钢琴演奏的技术水平进步非常大，顺利通过考级，各种比赛和表演也能从容应对。非常感谢SoMe音乐感谢Emily老师。'}}</div>
<!--            <div class="text3">— Parent</div>-->
          </div>
          <div class="comment-item">
            <div class="text1">“</div>
            <div class="text2">{{$store.state.curIndex==1?'Interest is the best teacher for a child, and a teacher who knows how to nurture a child\'s interests and teach according to their aptitude is the best teacher of all! We feel incredibly fortunate to have encountered Teacher Emily and her team! Over this year of piano study, Qizhi has made significant progress and his passion for the piano has only grow stronger, which aligns perfectly with my original intentions for his education and development. l am also deeply grateful to Teacher Zou for accompaniment practice and Teacher Du for music theory. They ensure rigorous, serious, and professional teaching quality while also making classes fun and engaging, enabling the children to grasp knowledge easily and achieve twice the results with half the effort! Most importantly, thanks to the teachers dedication, l can be a happy and worry-free mother, even though I know nothing about the piano.':'兴趣是孩子最好的老师，而懂得呵护孩子兴趣又能因材施教的老师更是最最好的老师！很幸运遇到Emily老师团队！这一年的钢琴学习，Qizhi进步很大，对钢琴的热情只增不减，这和我对孩子教育培养的初衷十分契合。也非常感谢陪练邹老师和乐理杜老师，在保证严谨认真专业的教学质量的同时也给足了课堂趣味性，让孩子能轻松掌握知识，事半功倍！最重要的是！有了老师们的费心，我也可以当一个快乐的不焦虑的对钢琴一窍不通的琴童妈妈。'}}</div>
<!--            <div class="text3">— Miss Yong, Sister</div>-->
          </div>
          <div class="comment-item">
            <div class="text1">“</div>
            <div class="text2">{{$store.state.curIndex==1?'Teacher Emily was recommended by another parent whose child attends the same school as oursBefore taking her classes, we had already studied with a well-known local piano teacher for young children, but my child came home telling me he didn\'t like that teacher and was very resistant to attending piano lessons. During this time, we took a break from piano lessons for about half a yearOn the first trial lesson with Emily, my child really enjoyed her class. As a Chinese mother, l can be quite picky😂), but l must give credit where it\'s due to Emily. After starting her classes, my child no longer resisted piano lessons and even began to tell me he would practice regularly. Thank you, Emily, for helping my child continue to enjoy playing the piano.':'Emily 老师是孩子同校家长推荐的，在上她的课之前，我们已经和一位据说是当地知名的幼儿钢琴老师学习了，但是我的孩子回来和我说他不喜欢这位老师，十分抗拒上钢琴课。这期间，我们暂停钢琴课大约有半年。第一次试上Emily的课，孩子就很喜欢她，作为中国妈妈的我真得很挑剔，但也为Emily点赞。上了她的课后，我的孩子变得不再抗拒钢琴课，并且主动和我说会不间断联系钢琴。谢谢Emily让我的孩子继续延续这份对钢琴的欢喜之心。'}}</div>
<!--            <div class="text3">— Janelle Lim, 8, Piano</div>-->
          </div>
        </div>
        <div class="more-button" @click="moreFn">{{$store.state.curIndex==1?'Read More':'更 多'}}</div>
      </div>
      <el-divider></el-divider>
      <div class="box-info">
        <h2 style="margin: 2.88em 0 -0.8em;">{{$store.state.curIndex==1 ? 'What makes SoMe Music School so unique?':'是什么让SoMe Music School如此与众不同呢？'}}</h2>
        <div class="box-info-item"></div>
         <div class="box-info-item">
           <p>{{$store.state.curIndex==1 ? 'Step into SoMe Music School and begin an extraordinary musical journey. When you enter our classrooms, everything will be ready for you - all you need to bring is your passion for music and a curiosity to explore.':'走进SoMe Music School，开启一段非凡音乐旅程的起点，当您踏入我们的教室，一切都已为您准备妥当——您只需带着对音乐的热爱与探索欲。'}}</p>
         </div>
         <div class="box-info-item">
           <h3>{{$store.state.curIndex==1?'Professional Music Teachers':'我们有专业的音乐教师'}}</h3>
           <p>{{$store.state.curIndex==1?'At SoMe Music School, every student\'s growth is supported by a professional team. From customized lesson plans to personalized guidance strategies, we are dedicated to ensuring that the quality of education progresses in sync with each students learning pace. Our goal is to help every student experience the joy of progress while enjoying the learning process.':'在SoMe Music School，每一位学生的成长背后都有一个专业团队的支持。从定制化的教学计划到个性化的指导策略，我们致力于确保教育质量与学习进度同步推进，使每位学员都能在愉悦中感受进步的喜悦。'}}
           </p>
         </div>
         <div class="box-info-item">
           <h3>{{$store.state.curIndex==1 ? 'Flexible Teaching Methodology':'我们在课上采用灵活的教学方法'}}</h3>
           <p>
             {{$store.state.curIndex==1?'Our personalized one-on-one courses are designed to perfectly fit each student\'s schedule and individual needs. Whether you have a busy work routine or a packed study plan, SoMe Music School will tailor the most suitable learning program just for you.':'我们的个性化一对一课程设计，完全贴合学生的时间安排和个人需求。无论您有着繁忙的工作日程，还是紧凑的学习计划，SoMe Music School都将为您量身定做最适合的学习方案。 '}}</p>
         </div>
         <div class="box-info-item">
           <h3>{{$store.state.curIndex==1 ? 'Fun and Engaging Class':'我们提供的课程寓教于乐'}}</h3>
           <p>{{$store.state.curIndex==1?'From the curiosity and excitement of first encountering an instrument, to playing the first complete melody, and finally performing confidently on stage, SoMe Music School is always a strong supporter on the path to achieving children\'s musical dreams. We accompany each student as they grow from beginners to skilled performers, witnessing every important milestone along the way.':'初次接触乐器的好奇与兴奋，到演奏出第一个完整的旋律，再到舞台上自信的表演，SoMe Music School始终是孩子实现音乐梦想道路上的坚强后盾。我们陪伴着每一位学生从初学者成长为技艺娴熟的演奏者，见证他们每一个重要的成长瞬间。'}}</p>
         </div>
         <div class="box-info-item">
           <p>{{$store.state.curIndex==1?'Choosing SoMe Music School means choosing a professional, personalized, and fun-filled musical journey. Let\'s embark on this wonderful musical adventure together!':'选择SoMe Music School，就是选择了专业、个性化且充满乐趣的音乐之旅。让我们携手开启这段美妙的音乐旅程吧！'}}</p>
         </div>
      </div>


      <!--        <div class="box-jump">-->
      <!--          <p>If you have further questions about our Music Theory classes or would like to see an outline of the curriculum, please contact us. We’d be happy to answer all of your questions!</p>-->
      <!--          <div class="jump-buttons">联系我们</div>-->
      <!--        </div>-->
    </div>
  </el-row>
<!--  <el-backtop target=".rowPos"></el-backtop>-->
</div>
</template>

<script>
import axios from 'axios';

export default {
    name: "HomeIndex",
    data() {
        return {
            imageUrl: '',
            descInfo: '笑容具有感染力，你的笑容价值百万',
          imgList2:[{
            name:'张驰',
            info: '小提琴家，毕业于新加坡杨秀桃国立音乐学院，师从左军教授，与众多国际知名演奏家和乐团同台演出，包括中国爱乐交响乐团等等。现担任新加坡少儿交响乐团小提琴主任，并获得终身首席职称。',
            src:require("@/assets/img/jiaoshi1.png"),
          },{
            name:'杜佳瑶',
            info:'乐理课讲师，毕业于湖南师范大学音乐学专业，重庆大学师资管理硕士学位。大学任教15年，拥有丰富的声乐、乐理、视唱练耳教学经验。发表期刊论文8篇，强调以文育人，以艺通心。',
            src:require("@/assets/img/jiaoshi2.png"),
          },
            {
              name:'邹祯',
              info:'钢琴陪练老师，热爱音乐、深耕钢琴教育多年，秉持“音乐启智，快乐成长”的教育理念，坚信每位学生都有独特的音乐潜能。',
              src:require("@/assets/img/jiaoshi3.jpg"),
            },
            {
              name:'罗寒月',
              info:'钢琴陪练老师，热爱钢琴教育事业，曾在英皇考级中多次拿到满分曲目（30/30）的成绩，多次参与国际、国内钢琴大赛，并获得不错的名次。',
              src:require("@/assets/img/jiaoshi4.jpg"),
            }],
            acctivateName: "small",
            aiTitleSmallList: [],
            activeIndex: 0,
            carouselItems1:"",
            carouselItems2: "",
            applyObj: {},
            currentPage: 1,
            pageSize: 2,
            type: 10,
            imgs: [],
            homepageSceneList:[],
            background:[],

        }
    },
    props: {},
    methods:{
      checkClass(ind) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth' // 可选，平滑滚动效果
        });
       if(ind==0) {
         this.$store.commit('setIndex','3-1')
         this.$router.push({path:'/PianoLessons'})
       }else if(ind==1) {
         this.$store.commit('setIndex','3-3')
         this.$router.push({path:'/ViolinLessons'})
       }else if(ind==2) {
         this.$store.commit('setIndex','3-2')
         this.$router.push({path:'/PianoSparring'})
       }else if(ind==3) {
         this.$store.commit('setIndex','3-4')
         this.$router.push({path:'/MusicTheoryLessons'})
       }

        },
      moreFn() {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth' // 可选，平滑滚动效果
        });
        // window.open(window.location.origin+'/#/moreComment')
        this.$store.commit('setIndex',0)
        this.$router.push({path:'/moreComment'})
        },


    },
     mounted() {
        // this.applyObj = this.boxFive[0];
        // window.addEventListener("load", () => {
        //     this.$store.commit("setIndex", "1")
        // })
        // console.log(this.imgs)
        // 获取首页场景
        // let homepageScene = await axios.get("http://hjjai.com:6789/img/getImgByType",{
        //     params:{
        //         currentPage: 1,
        //         pageSize: 3,
        //         type: 14
        //     }
        // })
        // this.homepageSceneList = homepageScene.data.data.data
        // let background = await axios.get("http://hjjai.com:6789/img/getImgByType",{
        //     params:{
        //         currentPage: 1,
        //         pageSize: 1,
        //         type: 12
        //     }
        // })
        // this.backgroundList = background.data.data.data
        console.log(this.homepageSceneList);
    },
    components: {
    },
    computed: {
      tops() {
        if(document.getElementById('Homes')) {
          return document.getElementById('Homes').clientTop
        }else return 0
      },
      imgList() {
        if(this.$store.state.curIndex==1) {
          return [
            {
              name: `${this.$store.state.curIndex == 1 ? 'Piano Lessons' : '钢琴课'}`,
              info: `${this.$store.state.curIndex == 1 ? 'Customized long-term music plan, integrating music theory and appreciation, to comprehensively enhance performance skills.' : '定制长期钢琴学习计划，融合乐理与赏析，全面提升演奏技巧。'}`,
              src: require("@/assets/img/piano.png"),
            }, {
              name: `${this.$store.state.curIndex == 1 ? 'Violin Lessons' : '小提琴课'}`,
              info: `${this.$store.state.curIndex == 1 ? 'Fun and engaging teaching to spark children\'s interest, with systematic learning to improve music literacy and technique.' : '趣味教学激发孩子兴趣，系统学习提升音乐素养与技巧。'}`,
              src: require("@/assets/img/xiaotiqin.png"),
            },
            {
              name: `${this.$store.state.curIndex == 1 ? 'Piano Partner' : '钢琴陪练'}`,
              info: `${this.$store.state.curIndex == 1 ? 'Professional teacher guidance for efficient practice, creating a positive learning environment.' : '专业教师指导，高效练习，营造积极的学习氛围。'}`,
              src: require("@/assets/img/piano2.png"),
            },
            {
              name: `${this.$store.state.curIndex == 1 ? 'Music Theory Lessons' : '乐理课'}`,
              info: `${this.$store.state.curIndex == 1 ? 'Focused on ABRSM exams, with expert tutoring that combines theory and practice to help you pass with ease.' : '专注ABRSM考试，专业教师辅导，结合理论与实践，助你轻松过关。'}`,
              src: require("@/assets/img/yueli.png"),
            }
          ]
        }else {
          return [
            {
              name: `钢琴课`,
              info: `定制长期钢琴学习计划，融合乐理与赏析，全面提升演奏技巧。`,
              src: require("@/assets/img/piano.png"),
            }, {
              name: `小提琴课`,
              info: `趣味教学激发孩子兴趣，系统学习提升音乐素养与技巧。`,
              src: require("@/assets/img/xiaotiqin.png"),
            },
            {
              name: `钢琴陪练`,
              info: `专业教师指导，高效练习，营造积极的学习氛围。`,
              src: require("@/assets/img/piano2.png"),
            },
            {
              name: `乐理课`,
              info: `专注ABRSM考试，专业教师辅导，结合理论与实践，助你轻松过关。`,
              src: require("@/assets/img/yueli.png"),
            }
          ]

        }
      }
    }
}
</script>

<style lang="less" scoped>
.colPos {
    position: relative !important;
  height: 600px;
  .is-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(33, 27, 23, .21);
  }

    img{
      //position: relative;
      //
      //top: -260px;
      margin-top: -10%;
    }

}

.main-content {
  background: #ffffff;
  padding-top: .8em;
  margin-bottom: 3em;
  .imgLists {
    display: flex;
    //margin-top: 20px;
    //align-items: center;
    margin-bottom: 2em;
    justify-content: space-between;
    flex-wrap: wrap;
    .imgLists-item {
      width: 31%;
      img {
        width: 100%;
        margin-bottom: .6em;
      }
      .p1 {
        white-space: pre-wrap;
        font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 2px;
        text-transform: none;
        line-height: 1.2em;
        color: #000;
        font-size: 18px;
      }
      .p2 {
        color: #1f1f1f;
        white-space: pre-wrap;
        font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
        font-weight: 300;
        font-style: normal;
        letter-spacing: 2px;
        text-transform: none;
        line-height: 1.7em;
        font-size: 13px;
      }
    }
    .imgLists-item2 {
      width: 48%;
    }
  }
  .main-content-box {
    max-width: 1020px;
    width: auto;
    padding:0 28px;
    margin: auto;
    .imgLists {
      display: flex;
      //margin-top: 20px;
      margin-bottom: 3.2em;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .imgLists-item {
        width: 23.5%;
        img {
          width: 100%;
          height: 188px;
          margin-bottom: .6em;
        }
        .p1 {
          white-space: pre-wrap;
          font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 2px;
          text-transform: none;
          line-height: 1.2em;
          color: #000;
          font-size: 18px;
        }
        .p2 {
          color: #1f1f1f;
          white-space: pre-wrap;
          font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
          font-weight: 300;
          font-style: normal;
          letter-spacing: 2px;
          text-transform: none;
          line-height: 1.6em;
          font-size: 12px;
        }
      }
    }
    .box-imgs {
      margin: 2.2em 0;
      img {
        width: 100%;
        height: auto;
      }
    }
    h1 {
      margin: 1.68em 0 .85em;
      white-space: pre-wrap;
      text-align: center;
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      color: rgb(0,162,224);
      font-weight: 600;
      font-style: normal;
      font-size: 30px;
      letter-spacing: 2px;
      text-transform: none;
      line-height: 1.2em;
    }
    .box-info {
      margin-bottom: 2.2em;
      .comment {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 45px;
        .comment-item {
          margin-bottom: 20px;
          width: 30%;
          .text1 {
            display: block;
            opacity: .3;
            font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
            font-weight: 300;
            font-style: normal;
            font-size: 50px;
            letter-spacing: 2px;
            line-height: 38px;
            color: #000;
            padding: 30px 32px 0;
          }
          .text2 {
            font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
            font-weight: 300;
            font-style: normal;
            font-size: 18px;
            letter-spacing: 2px;
            line-height: 1.7em;
            color: #000;
            text-align: left;
            //padding: 32px 32px 0;
          }
          .text3 {
            font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
            font-weight: 300;
            font-style: normal;
            font-size: 18px;
            letter-spacing: 0px;
            line-height: 1.4em;
            color: rgba(26, 26, 26, .9);
            //padding: 32px 32px 0;
            //font-size: .875em;
            padding-top: 1em;
            opacity: .5;
          }
        }
      }
    }
    .box-info-item {
      margin-top: 3em;
    }
    h3 {
      display: inline-block !important;
      margin: .0em 0 .95em;
      white-space: pre-wrap;
      color:rgb(0,162,224);
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 600;
      font-style: normal;
      font-size: 17px;
      letter-spacing: 2px;
      text-transform: uppercase;
      line-height: 1.3em;
      }
    h2 {
      margin: 1.82em 0 1em;
      text-align: center;
      white-space: pre-wrap;
      color:rgb(0,162,224);
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 500;
      font-style: normal;
      font-size: 22px;
      letter-spacing: 2px;
      //text-transform: uppercase;
      line-height: 1.3em;
      }
    p,ul li {
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 300;
      font-style: normal;
      font-size: 17px;
      letter-spacing: 2px;
      line-height: 1.6em;
      color: #000;
      white-space: pre-wrap;
      margin-bottom: .5em;
    }
    ul {
      margin-left: 36px;
      li {
        margin: 0.5em 0;
      }
    }
    .box-jump {
      margin: 2em 0;
      .jump-buttons {
        width: 150px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #111;
        border: 2px solid #111;
        font-size: 16px;
        border-radius: 2px;
        margin: 2.6em auto;
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }
  }

}
.more-button {
  display: inline-block;
  margin: 0 auto 20px;
  width: 142px;
  height: 58px;
  line-height: 56px;
  text-align: center;
  color: #111;
  border: 2px solid #111;
  font-size: 17px;
  border-radius: 2px;
  font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.more-button:hover {
  background-color: #111;
  color: #fff;
}
.desc-wrapper {
  animation: feature-text-anim .75s ease-in-out;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-42%);
  width: 100%;
  max-width: 956px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  p {
    white-space: pre-wrap;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 600;
    font-style: normal;
    font-size: 46px;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.3em;
    color: #fff;
    margin: 20px auto;
    -webkit-transform: translatez(0);
  }
}

</style>
