import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const CoreDataModeles = {
  state: {
    data: {

    },
  },
  getters: {
    getAll(state) {
      return state.data;
    },
  },
  mutations: {},
  actions: {
    // 请求初始化数据
    async getData(state) {

    },
  },
  modules: {},
};

const IndustrySolutionsHoverMoudeles = {
  state: {
    IndustrySolutionsHoverData: [
      {
        name: "智慧社区AI算法方案",
        path: '/smartcommunity'
      },
      {
        name: "智慧园区AI算法方案",
        path: '/smartpark'
      },
      {
        name: "智慧工地AI算法方案",
        path: '/successcases'
      },
      {
        name: "智慧门店AI算法方案",
        path: '/SmartStore'
      },
      { name: "智慧工厂AI算法方案",
        path: '/smartfactory'
      },
      { name: "园区算法方案" ,
        path: '/'
    },
      { name: "安防算法方案" ,
        path: '/'
    },
      {
        name: "亮灶算法方案",
        path: '/'
      },
      { name: "零售算法方案" ,
        path: '/'
    },
      {
        name: "水务AI算法方案",
        path: '/'
      },
      { name: "加油站算法方案",
        path: '/'
    },
      {
        name: "新能源算法方案",
        path: '/'
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
};
export default new Vuex.Store({
  state: {
    boxWidth: document.documentElement.clientWidth,
    boxTop: document.documentElement.offsetTop,
    showMenu: false,
    showNav: true,
    index: "1",
    isRemove: true,
    isXdfa: true,
    curIndex: 1,
  },
  getters: {},
  mutations: {
    setIndex(state, value) {
      state.index = value;
    },
    setIsRemover(state, value) {
      state.isRemove = value;
    },
    setIsXdfa(state, value) {
      state.isXdfa = value;
    },
    setBoxWidth(state, value) {
      state.boxWidth = value;
    },
    setShowMenu(state, value) {
      state.showMenu = value;
    },
    setShowNav(state, value) {
      state.showNav = value;
    },
    setCurIndex(state, value) {
      state.curIndex = value;
    },
  },
  actions: {},
  modules: {
    CoreData: CoreDataModeles,
    ISHData: IndustrySolutionsHoverMoudeles,
  },
});
