<template>
  <div id="Home">
    <el-row class="rowPos">
      <el-col :span="24" class="colPos">
        <!--        <div class="is-background"></div>-->
        <img src="@/assets/img/bg7.jpg" style="width: 100%;height: auto;">
        <div class="desc-wrapper">
          <p>{{descInfo}}</p>
        </div>
        <!--            <el-carousel height="600px" :interval=5000 style="z-index: -1;">-->
        <!--              <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">-->
        <!--&lt;!&ndash;            <el-carousel-item v-for="(item, index) in imgs" :key="index">&ndash;&gt;-->
        <!--&lt;!&ndash;                <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">&ndash;&gt;-->
        <!--&lt;!&ndash;            </el-carousel-item>&ndash;&gt;-->
        <!--        </el-carousel>-->
      </el-col>
    </el-row>
    <el-row class="main-content">
      <div class="main-content-box">
        <div class="box-info">
          <h1>School Policies</h1>
          <p>以下详细说明了我们音乐课程服务的条款和条件。如果您需要任何说明，请联系我们。</p>
        </div>
        <div class="box-info">
          <p><strong>本文件中使用的术语定义如下：</strong></p>
          <p>学校 - 指的是 SoMe Music School </p>
          <p>您的老师 – 为您指派音乐课的老师。  </p>
          <p>学校日历 – SoMe Music School的内部学校日历 位于我们的网站上。 </p>
          <p>学生 – 入学时在注册表上注明的注册学生或父母/法定监护人（如果学生未满 18 岁）。 </p>
          <p>课程时段 – 固定的每周课程安排（日期和时间），由学生和学校在报名时商定。 </p>
          <p>补修学分 – 弥补错过的课程。 </p>
          <p>信用记录 – 错过课程的信用价值。 </p>
        </div>
        <div class="box-info">
          <p><strong>1 - 报名</strong></p>
          <p>1.1   学生入学时须缴纳规定的注册费。</p>
          <p>1.2   学生须在报名时缴纳规定的押金，如需退还押金，需提前 30 天通知。</p>
          <p>1.3   一经报名，学生即被视为已阅读并同意我们的条款和条件。</p>
        </div>
        <div class="box-info">
          <p><strong>2 - 计费 </strong></p>
          <p><strong>对于个人每周常规课程： </strong></p>
          <p>2.1   费用在单月收取，一次收取两个月，全年总共44课时。请查看我们的校历以了解具体日期。</p>
          <p>2.2   所有费用均应于账单上注明的日期到期支付。 </p>
          <p>2.3   如果学生未能在账单通知单到期日后的 14 天内支付当月的费用，则将从学生的账单通知单中增加 10 新币的滞纳金。 </p>
          <p>2.4   如果学生在账单到期日后 30 天内仍未支付发票，学校保留终止学生入学并向公众发布课程时段的权利。</p>
          <p>2.5   费用不可退还，不可转让，兄弟姐妹或直系亲属除外。</p>
          <p><strong>对于成人弹性课程包：</strong></p>
          <p>2.6   所有课程套餐的费用必须在第一次预定课程日期前全额支付。</p>
          <p>2.7   套餐内的所有课程必须在规定的截止日期前完成。</p>
          <p>2.8   T课程已过期，恕不退款。学生可通过我们网站上的申诉表申请延长课程期限。</p>
        </div>
        <div class="box-info">
          <p><strong>3 - 取消课程</strong></p>
          <p>3.1   任何课程取消均需在受影响课程开始前至少24 小时通知学校。对于旅行、学校考试或活动等非紧急取消，我们要求提前 7 天通知。学校将发放补课积分，并在学校补课日或其他预先安排的时间为学生安排补课。请参阅我们的校历了解更多详情。</p>
          <p>3.2   最后一刻取消（提前不到 24 小时通知）将导致课程正常扣除，除非提供医疗证明。学生可以通过我们网站上的申诉表来申诉恢复课程。</p>
          <p>3.3   如果由于您的老师无法上课（如医疗、预备役或个人休假）而取消课程，学校将提供替代老师，或发出补课券并相应地与您安排补课，或发出可用于支付下个月费用的信用票据，由我们自行决定。 </p>
          <p>3.4   补修学分可以与另一位老师另外一个乐器一起使用，也可以转让给兄弟姐妹或直系亲属。 </p>
          <p>3.5   如果在退出后的 30 天通知期最后一天之前没有使用，则未使用的补足积分将被没收。 </p>
        </div>
        <div class="box-info">
          <p><strong>4 - 暂停课程 </strong></p>
          <p>4.1  学生可因住院、旅行、丧亲、预备役或重大考试等情况申请暂时停课；请在停课开始日期前至少 14 天向学校  提交暂时停课申请表，连同理由和其他证明文件。  </p>
          <p>4.2   暂停期间，学生的课程时段最多可保留 30 天。30 天后，学校保留向公众开放课程时段的权利。若要将此期限再延长 30 天，需预付相当于一个月课程费用的费用。如果学生不恢复上课，此付款不予退还。课程时段不得保留超过 60 天。 </p>
          <p>4.3   如需恢复课程，必须至少在预计开始日期前 14 天以书面形式提前通知学校。 </p>
        </div>
        <div class="box-info">
          <p><strong>5 – 退出课程  </strong></p>
          <p>5.1    退课必须至少提前30天通过提交退课申请表通知学校。   </p>
          <p>5.2    如果出现以下情况，学生押金将退还给学生： </p>
          <p style="margin-left:40px;" class="">i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 提款申请表至少在提款日期前 30 天提交 </p>
          <p style="margin-left:40px;" class="">ii)&nbsp;&nbsp;&nbsp; 所有未付发票均已付清, 学校保留使用学生押金来抵消任何未付发票的权利。</p>
          <p>5.3   学生必须在 30 天的通知期内完成所有已付费课程和未使用的补修学分。未使用的课程或补修学分不予退款。</p>
          <p>5.4 如果超过 14 天无法通过电话、短信、电子邮件等合理方式联系到学生，学校保留终止报名并向公众公布课程时段的权利。</p>
        </div>
        <div class="box-info">
          <p><strong>6 - 媒体发布  </strong></p>
          <p>6.1   学生明白学校可能会在工作室、活动或活动中拍摄照片和视频，用于培训、营销或文档目的。     </p>
          <p>6.2   学生授权学校在我们所有的出版物（包括网络出版物）中使用包含其肖像的照片、视频或其他数字媒体，无需支付任何费用或其他报酬。 我们会尽力但不能保证在使用前告知学生照片中有学生。 </p>
        </div>
        <div class="box-info">
          <p><strong>7 - 版权所有  </strong></p>
          <p>7.1   学校保留在合理情况下更改课程安排、修改课程费用、更换教师、终止学生课程的权利。   </p>
          <p>7.2   学校保留修改本协议规定的任何条款的所有权利，包括但不限于 - 增加/删除任何条款、调整费用等。 </p>
          <p>7.3 如有任何争议，学校保留最终决定权。</p>
        </div>
        <div class="box-info" style="margin-top: 4.1em">
          <h1>校历安排</h1>
          <img src="@/assets/img/xiaoli.jpeg" style="width: 80%">
        </div>
      </div>
    </el-row>




  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "CurriculumSystem",
  data() {
    return {
      imageUrl: '',
      descInfo: '学校政策',


    }
  },
  props: {},
  methods: {
    imgPath() {
      return 'http://hjjai.com:6789/commonImg/'
    },
    steCarou(nub){
      this.carouselItems1 = this.carouselItems[nub].title;
      this.carouselItems2 = this.carouselItems[nub].content;
      console.log(this.carouselItems[nub].content);
      console.log(this.carouselItems[nub].title);

    },
    changeIn(val){
      // console.log(val);
      this.steCarou(val)
    },
    boxFiveDemo(event) {
      let obj = this.boxFive.filter((item) => {
        return item.id.toString() === event.target.id;
      });
      this.applyObj = obj[0];
      this.boxFive[event.target.id-1].Icon=true
    },

  },
  async mounted() {
    this.applyObj = this.boxFive[0];
    // window.addEventListener("load", () => {
    //     this.$store.commit("setIndex", "1")
    // })
    const result = await axios.get('http://hjjai.com:6789/img/getImgByType', {
      params: {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        type: this.type
      }
    })
    this.imgs = result.data.data.data
    // console.log(this.imgs)
    // 获取首页场景
    // let homepageScene = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 3,
    //         type: 14
    //     }
    // })
    // this.homepageSceneList = homepageScene.data.data.data
    // let background = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 1,
    //         type: 12
    //     }
    // })
    // this.backgroundList = background.data.data.data
    console.log(this.homepageSceneList);
  },
  components: {
  },
  computed: {
    currentContent(){
      return this.carouselItems[this.activeIndex].content;
    }
  }
}
</script>

<style lang="less" scoped>
.colPos {
  position: relative !important;
  height: 600px;
  overflow: hidden;
  .is-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(33, 27, 23, .21);
  }
  img {
    //position: relative;
    //
    //top: -260px;
    margin-top: -13%;
  }

}


.main-content {
  background: #ffffff;
  padding-top: 1.8em;
  .box-info {
    margin: 3.1em 0;
  }
  .main-content-box {
    max-width: 1020px;
    width: auto;
    padding:0 28px !important;
    margin: auto;
    p,ul li {
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 300;
      font-style: normal;
      font-size: 17px;
      letter-spacing: 2px;
      line-height: 1.6em;
      color: #000;
      white-space: pre-wrap;
      margin-bottom: .72em;
    }
    h1 {
      color:  rgb(0,162,224);
      font-weight: bold;
      font-style: normal;
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-size: 30px;
      letter-spacing: 2px;
      text-transform: none;
      line-height: 1.2em;
      margin: 1em 0 .5em;
    }
  }
}
.desc-wrapper {
  animation: feature-text-anim .75s ease-in-out;
  top: 52%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-42%);
  width: 100%;
  max-width: 956px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  p {
    white-space: pre-wrap;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 600;
    font-style: normal;
    font-size: 56px;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.3em;
    color: #fff;
    margin: 20px auto;
    -webkit-transform: translatez(0);
  }
}
</style>
