<template>
  <div id="Home">
    <el-row class="rowPos">
      <el-col :span="24" class="colPos">
<!--        <div class="is-background"></div>-->
        <img src="@/assets/img/back7.jpg" style="width: 100%;height: auto;">
        <div class="desc-wrapper">
          <p>{{$store.state.curIndex==1?'NEWS':'资讯'}}</p>
        </div>
        <!--            <el-carousel height="600px" :interval=5000 style="z-index: -1;">-->
        <!--              <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">-->
        <!--&lt;!&ndash;            <el-carousel-item v-for="(item, index) in imgs" :key="index">&ndash;&gt;-->
        <!--&lt;!&ndash;                <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">&ndash;&gt;-->
        <!--&lt;!&ndash;            </el-carousel-item>&ndash;&gt;-->
        <!--        </el-carousel>-->
      </el-col>
      <!--      <div style="height: 600px;background-color: rgba(33,27,23,.21);"></div>-->
    </el-row>
    <el-row class="main-content">
      <div class="main-content-box">
<!--        <div class="box-info">-->
<!--          <h1>荣誉奖章</h1>-->
<!--&lt;!&ndash;          <p style="text-align: center;color: #1f1f1f">以下是我们获得的部分荣誉奖章</p>&ndash;&gt;-->
<!--          <div class="imgLists">-->
<!--            &lt;!&ndash;            <div v-for="(item,i) in imgList" :key="i" class="imgLists-item">&ndash;&gt;-->
<!--            <div class="imgLists-item">-->
<!--              <img src="@/assets/img/j7.png">-->
<!--            </div>-->
<!--            <div class="imgLists-item">-->
<!--              <img src="@/assets/img/j8.png" >-->
<!--            </div>-->
<!--            <div class="imgLists-item">-->
<!--              <img src="@/assets/img/j9.png" >-->
<!--            </div>-->
<!--            <div class="imgLists-item">-->
<!--              <img src="@/assets/img/j10.png">-->
<!--            </div>-->
<!--            <div class="imgLists-item">-->
<!--              <img src="@/assets/img/j12.png">-->
<!--            </div>-->
<!--            <div class="imgLists-item">-->
<!--              <img src="@/assets/img/j13.png">-->
<!--            </div>-->
<!--            <div class="imgLists-item">-->
<!--              <img src="@/assets/img/j14.png">-->
<!--            </div>-->
<!--            <div class="imgLists-item">-->
<!--              <img src="@/assets/img/j15.png">-->
<!--            </div>-->
<!--            <div class="imgLists-item">-->
<!--              <img src="@/assets/img/j16.png">-->
<!--            </div>-->
<!--            &lt;!&ndash;              <p class="p1">{{item.name}}</p>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--          </div>-->
<!--&lt;!&ndash;          <p style="text-align: center"> Here are some of our FAVOURITE pictures from some of our FAVOURITE events!</p>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="imgLists">&ndash;&gt;-->
<!--&lt;!&ndash;            <div v-for="(item,i) in imgList" :key="i" class="imgLists-item">&ndash;&gt;-->
<!--&lt;!&ndash;              <img :src="item.src">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;              <p class="p1">{{item.name}}</p>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->

        <div class="box-info">
          <h1>{{$store.state.curIndex==1 ? 'Student Recitals' : '历届学生音乐会'}}</h1>
          <h2>{{$store.state.curIndex==1 ? '2024 annual Recital' : '2024音乐会'}}</h2> <div class="imgLists">
          <!--            <div v-for="(item,i) in imgList" :key="i" class="imgLists-item">-->
          <div class="imgLists-item">
            <img src="@/assets/img/20243.jpg">
          </div>
          <div class="imgLists-item">
            <img src="@/assets/img/yin43.jpg">
          </div>
          <div class="imgLists-item">
            <img src="@/assets/img/yin48.jpg">
          </div>
          <div class="imgLists-item">
            <img src="@/assets/img/20242.jpg">
          </div>
          <div class="imgLists-item">
            <img src="@/assets/img/yin46.jpg">
          </div>
          <div class="imgLists-item">
            <img src="@/assets/img/yin42.jpg">
          </div>
        </div>
          <h2>{{$store.state.curIndex==1 ? '2023' : '2023音乐会'}}</h2>
<!--          <p style="text-align: center"> Here are some of our FAVOURITE pictures from some of our FAVOURITE events!</p>-->
          <div class="imgLists">
<!--            <div v-for="(item,i) in imgList" :key="i" class="imgLists-item">-->
              <div class="imgLists-item">
                <img src="@/assets/img/20231.jpg">
              </div>
            <div class="imgLists-item">
              <img src="@/assets/img/20232.jpg" >
            </div>
            <div class="imgLists-item">
              <img src="@/assets/img/20233.jpg" >
<!--              <img src="@/assets/img/y2.jpeg" >-->
            </div>
            <div class="imgLists-item">
              <img src="@/assets/img/yin2.jpg">
            </div>
            <div class="imgLists-item">
              <img src="@/assets/img/yin5.jpg">
            </div>
            <div class="imgLists-item">
              <img src="@/assets/img/yin8.jpg">
            </div>
            <h2>{{$store.state.curIndex==1 ? '2022 Christmas Public Recital' : '2022年圣诞节公开演奏会'}}</h2>

            <!--              <p class="p1">{{item.name}}</p>-->
<!--            </div>-->
          </div>
          <el-divider></el-divider>


        </div>
        <div class="box-info">
                    <h1>{{$store.state.curIndex==1 ? 'Competitions' : '比赛'}}</h1>




        </div>
        <el-divider></el-divider>
        <div class="box-info">
          <h1>{{$store.state.curIndex==1 ? 'Outdoor Immersive Classroom':'户外商业教室'}}</h1>
          <h2>{{$store.state.curIndex==1 ? 'Explore Diverse Musical Themes':'探索不同的音乐主题'}}</h2>
          <p>{{$store.state.curIndex==1 ? 'Foster your child\'s appreciation of music cultures around the world! Watch them learn with immersive play!':'培养孩子对世界各地音乐文化的欣赏！看着他们在默剧中学习！'}}</p>
          <h2>{{$store.state.curIndex==1?'Connect Music to Other Subjects':'将音乐与其他学科联系起来'}}</h2>
          <p>{{$store.state.curIndex==1 ?'Enrich your child\'s education with interdisciplinary learning! Watch them connect how music intersects with everyday life!':'通过跨学科学习丰富孩子的教育！观看他们如何将音乐与日常生活联系起来！'}}</p>
          <h2>{{$store.state.curIndex==1 ?'Build Teamwork and Coordination':'建立团队合作和协调'}}</h2>
          <p>{{$store.state.curIndex==1 ?'Challenge your child to work as a team in interactive activities! Watch them make new friends.':'挑战你的孩子在互动活动中作为一个团队工作！看着他们结交新朋友，一起体验成功！'}}</p>
          <h2>{{$store.state.curIndex==1 ?'Nurture Creative Expressions':'培养创造性表达'}}</h2>
          <p>{{$store.state.curIndex==1 ?'Inspire your child to express themselves creatively! Watch them explore music differently and nurture their voices!':'鼓励你的孩子创造性地表达自己！看着他们以不同的方式探索音乐，培养自己的声音！'}}</p>
          <h2>{{$store.state.curIndex==1?'Discover a new side of Music':'发现音乐的新一面'}}</h2>
          <p>{{$store.state.curIndex==1?'Don\'t miss the chance to spark a lifelong love for music! Let them experience music from outside the classroom.':'不要错过激发对音乐终身热爱的机会！让他们在教室外体验音乐。'}}</p>
          <div class="imgLists">
            <!--            <div v-for="(item,i) in imgList" :key="i" class="imgLists-item">-->
            <div class="imgLists-item">
              <img src="@/assets/img/h1.png">
              <img src="@/assets/img/h2.jpg">
            </div>
            <div class="imgLists-item">
              <img src="@/assets/img/h5.jpg" >
              <img src="@/assets/img/h7.png" >
            </div>
            <div class="imgLists-item">
              <img src="@/assets/img/h3.png" >
            </div>
            <div class="imgLists-item imgLists-item2">
              <img src="@/assets/img/m2.jpg">
              <img src="@/assets/img/m3.jpg">
            </div>
            <div class="imgLists-item imgLists-item2">
              <img src="@/assets/img/m1.png" >
              <img src="@/assets/img/m5.png" >
              <img src="@/assets/img/m4.png" >
            </div>
          </div>
<!--          <el-divider></el-divider>-->
<!--          <h2>【霍格沃茨的魔法旋律】哈利波特主题乐器合奏&主题绘画创作</h2>-->
<!--          <p>跨越年龄界限的趣味活动，适合所有热爱音乐与艺术的朋友参加。结交新朋友，共同探索音乐与魔法的魅力。</p>-->
<!--          <p>在轻松愉快的氛围中提升自我，发现潜在的艺术天赋。</p>-->
<!--          <div class="imgLists">-->
<!--            &lt;!&ndash;            <div v-for="(item,i) in imgList" :key="i" class="imgLists-item">&ndash;&gt;-->
<!--          -->
<!--            &lt;!&ndash;              <div class="imgLists-item">&ndash;&gt;-->
<!--            &lt;!&ndash;                <img src="@/assets/img/m4.png" >&ndash;&gt;-->
<!--            &lt;!&ndash;              </div>&ndash;&gt;-->
<!--            &lt;!&ndash;              <p class="p1">{{item.name}}</p>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--          </div>-->
        </div>
<!--        <div style="width: 100%">-->
<!--          <hr style="color: #fff">-->
<!--        </div>-->
<!--        <div class="box-info">-->
<!--        <h2>OUR COMMITMENT TO YOU:</h2>-->
<!--          <ul>-->
<!--            <li>We will continue to create opportunities for students to learn and play beautiful music.</li>-->
<!--            <li>We will continue to inspire our students to excellence.</li>-->
<!--            <li>We will continue to bring communities together through music.</li>-->
<!--          </ul>-->
<!--        </div>-->



        <!--        <div class="box-jump">-->
        <!--          <p>If you have further questions about our Music Theory classes or would like to see an outline of the curriculum, please contact us. We’d be happy to answer all of your questions!</p>-->
        <!--          <div class="jump-buttons">联系我们</div>-->
        <!--        </div>-->
      </div>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "HomeIndex",
  data() {
    return {
      imageUrl: '',
      descInfo: '资讯',
      acctivateName: "small",
      aiTitleSmallList: [],
      activeIndex: 0,
      carouselItems1:"",
      carouselItems2: "",
      applyObj: {},
      currentPage: 1,
      pageSize: 2,
      type: 10,
      imgs: [],
      homepageSceneList:[],
      background:[],

    }
  },
  props: {},
  methods: {
    imgPath() {
      return 'http://hjjai.com:6789/commonImg/'
    },
    steCarou(nub){
      this.carouselItems1 = this.carouselItems[nub].title;
      this.carouselItems2 = this.carouselItems[nub].content;
      console.log(this.carouselItems[nub].content);
      console.log(this.carouselItems[nub].title);

    },
    changeIn(val){
      // console.log(val);
      this.steCarou(val)
    },
    boxFiveDemo(event) {
      let obj = this.boxFive.filter((item) => {
        return item.id.toString() === event.target.id;
      });
      this.applyObj = obj[0];
      this.boxFive[event.target.id-1].Icon=true
    },

  },
  async mounted() {
    this.applyObj = this.boxFive[0];
    // window.addEventListener("load", () => {
    //     this.$store.commit("setIndex", "1")
    // })
    const result = await axios.get('http://hjjai.com:6789/img/getImgByType', {
      params: {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        type: this.type
      }
    })
    this.imgs = result.data.data.data
    // console.log(this.imgs)
    // 获取首页场景
    // let homepageScene = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 3,
    //         type: 14
    //     }
    // })
    // this.homepageSceneList = homepageScene.data.data.data
    // let background = await axios.get("http://hjjai.com:6789/img/getImgByType",{
    //     params:{
    //         currentPage: 1,
    //         pageSize: 1,
    //         type: 12
    //     }
    // })
    // this.backgroundList = background.data.data.data
    console.log(this.homepageSceneList);
  },
  components: {
  },
  computed: {
    currentContent(){
      return this.carouselItems[this.activeIndex].content;
    }
  }
}
</script>

<style lang="less" scoped>
.colPos {
  position: relative !important;
  height: 620px;
  .is-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(33, 27, 23, .21);
  }
  img {
    //position: relative;
    //
    //top: -260px;
    margin-top: -30%;
  }

}

.main-content {
  background: #ffffff;
  padding-top: .8em;
  margin-bottom: 3em;
  .main-content-box {
    max-width: 1020px;
    width: auto;
    padding:0 28px !important;
    margin: auto;
    .imgLists {
      display: flex;
      margin-top: 20px;
      //align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .imgLists-item {
        width: 31.5%;
        img {
          width: 100%;
          margin-bottom: .6em;
        }
      }
      .imgLists-item2 {
        width: 48%;
      }
    }
    .box-imgs {
      margin: 2.2em 0;
      img {
        width: 100%;
        height: auto;
      }
    }
    h1 {
      margin: 2.75em 0 .76em;
      white-space: pre-wrap;
      text-align: center;
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      color: rgb(0,162,224);
      font-weight: 600;
      font-style: normal;
      font-size: 30px;
      letter-spacing: 2px;
      text-transform: none;
      line-height: 1.2em;
    }
    .box-info {
      margin-bottom: 4.5em;
    }
    .box-info-item {
      margin-top: 3.5em;
    }
    h2 {
      margin: 1.5em 0 .65em;
      white-space: pre-wrap;
      color:#000;
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 600;
      font-style: normal;
      font-size: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
      line-height: 1.5em;
    }
    p,ul li {
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 300;
      font-style: normal;
      font-size: 17px;
      letter-spacing: 2px;
      line-height: 1.6em;
      color: #000;
      white-space: pre-wrap;
      margin-bottom: .1em;
      text-align: left;text-indent: 2em
    }
    ul {
      margin-left: 36px;
      li {
        margin: 0.5em 0;
      }
    }
    .box-jump {
      margin: 2em 0;
      .jump-buttons {
        width: 150px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #000;
        border: 2px solid #111;
        font-size: 16px;
        border-radius: 2px;
        margin: 2.6em auto;
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }
  }

}
.desc-wrapper {
  animation: feature-text-anim .75s ease-in-out;
  top: 52%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-42%);
  width: 100%;
  max-width: 956px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  p {
    white-space: pre-wrap;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 600;
    font-style: normal;
    font-size: 56px;
    letter-spacing:6px;
    text-transform: uppercase;
    line-height: 1.3em;
    color: #fff;
    margin: 20px auto;
    -webkit-transform: translatez(0);
  }
}
/deep/ .el-divider--horizontal {
  margin: 45px 0;
}
</style>
