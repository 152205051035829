<template>
  <div id="Home">
    <el-row class="rowPos">
      <el-col :span="24" class="colPos" style="background: rgb(246,221,0);display: flex;justify-content: center;align-items: center">
<!--        <div class="is-background"></div>-->
        <img src="@/assets/img/logo.png" style="width: 42%;height: 86%;">
        <div class="desc-wrapper">
          <p>{{$store.state.curIndex==1?'Contact Us':'联系我们'}}</p>
        </div>
        <!--            <el-carousel height="600px" :interval=5000 style="z-index: -1;">-->
        <!--              <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">-->
        <!--&lt;!&ndash;            <el-carousel-item v-for="(item, index) in imgs" :key="index">&ndash;&gt;-->
        <!--&lt;!&ndash;                <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">&ndash;&gt;-->
        <!--&lt;!&ndash;            </el-carousel-item>&ndash;&gt;-->
        <!--        </el-carousel>-->
      </el-col>
      <!--      <div style="height: 600px;background-color: rgba(33,27,23,.21);"></div>-->
    </el-row>
    <el-row class="main-content">
      <div class="main-content-box">
        <div class="box-info">
<!--          <h1>有任何疑问都欢迎与我们联系</h1>-->
          <p style="text-align: left;text-indent: 2em;">{{$store.state.curIndex==1 ? 'Hey, music lovers! 🎵 Want to learn more about our courses? Simply give us a call, send a WhatsApp message, drop us an email, or fill out our online form to easily get all the details you need. Even better, we warmly welcome you to visit our studio for a face-to-face consultation. We\'re here, ready to answer all your questions and make your musical journey even more exciting! Come join us!' : '嘿，音乐爱好者们！🎵 想要深入了解我们的课程吗？只需一个电话、一条WhatsApp消息、一封电子邮件，或者通过我们的在线表单，就能轻松获取所有你想知道的音乐课程细节。更棒的是，我们热烈欢迎您亲自来我们的工作室转转，享受一场面对面的咨询体验。我们在这里，随时准备解答你的每一个问题，让你的音乐之旅更加精彩！快来加入我们吧！'}}</p>
        </div>
        <div class="box-info">
          <h2>{{$store.state.curIndex==1 ? 'OUR LOCATION' : '我们的地址'}}</h2>
        <div style="float: left;width: 52%;margin-right: 20px" class="ditu-img">
          <img src="@/assets/img/ditu.png" style="width: 100%;height: 428px">
        </div>
          <h2 style="margin-top: 0;color: rgb(0,162,224)">SoMe Music School</h2>
          <p style="letter-spacing: 1px"><strong>66 E Coast Rd, B1-02 the Flow, Singapore 428778</strong></p>
          <p style="margin-bottom: 1.16em">{{$store.state.curIndex==1 ? 'We are located near l12 Katong, opposite the Holiday Inn Katong branch.' : '我们在东海岸地标商场I12附近，Holiday Inn加东分店的对面，The Flow商场负一楼第一间门店。'}}</p>
          <div style="display: flex;align-items: flex-end;"><p style="font-weight: bold;font-size: 19px">{{$store.state.curIndex==1?'Contact Phone:':'联系电话：'}}</p> <p style="font-weight: bold;font-size: 19px">+65 80398964</p></div>
          <div style="display: flex;align-items: flex-end;margin-bottom: .35em"><p style="font-weight: bold;font-size: 19px">WhatsApp：</p> <p style="font-weight: bold;font-size: 19px">+65 80398964</p></div>
          <div style="display: flex;align-items: flex-end"><p style="font-weight: bold">Email：</p> <p>somemusicschool@gmail.com</p></div>
          <p><strong>{{$store.state.curIndex==1?'MRT':'地铁'}}：</strong> {{$store.state.curIndex==1 ? 'Take the Brown Line to Marine Parade Station (Exit 3)':'棕线 marine parade马林百列地铁站3号出口'}}</p>
          <p><strong>{{$store.state.curIndex==1?'Driving：' : '自驾：'}}</strong> {{$store.state.curIndex==1 ? 'You can park at The Flow mall or in the parking lot of the Holiday Inn across the street.':'可以停在商场the flow，也可以停在对面Holiday Inn的停车场。'}}</p>
          <p><strong>{{$store.state.curIndex==1?'Bus：' : '公交车：'}}</strong> {{$store.state.curIndex==1?'Take bus 10,12, 14, 32 or 40.' : '10 12 14 32或40'}}</p>
        </div>
          <div class="box-lines"></div>
          <div class="box-info">
            <h3>{{ $store.state.curIndex==1 ? 'Or fill out this online form' : '或者填好以下表格'}}：</h3>
            <div class="box-form">
              <el-form ref="submitForm" :model="form" label-position="top" :rules="formRules">
                <el-form-item :label="`${$store.state.curIndex==1?'Student\'s Name (required)':'学生姓名(中/英)：'}`" prop="userName">
                  <el-input v-model="form.userName" clearable></el-input>
                </el-form-item>
                <el-form-item :label="`${$store.state.curIndex==1?'Gender':'性别'}：`" prop="sex">
                  <el-radio-group v-model="form.sex">
                    <el-radio :label="`${ $store.state.curIndex==1?'boy':'男'}`"></el-radio>
                    <el-radio :label="`${ $store.state.curIndex==1?'girl':'女'}`"></el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item :label="`${$store.state.curIndex==1?'Music LessonS':'试课课程'}：`" prop="course">
                  <el-checkbox-group v-model="form.course">
                    <el-checkbox :label="`${$store.state.curIndex==1?'Piano':'钢琴课'}`" name="type"></el-checkbox>
                    <el-checkbox :label="`${$store.state.curIndex==1?'Violin':'小提琴课'}`" name="type"></el-checkbox>
                    <el-checkbox :label="`${$store.state.curIndex==1?'Piano Partner':'钢琴陪练课'}`" name="type"></el-checkbox>
                    <el-checkbox :label="`${$store.state.curIndex==1?'Music Theory':'乐理课'}`" name="type"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item :label="`${$store.state.curIndex==1?'Parent\'s Mobile Number(required)':'学生/家长的联系方式（手机号）'}：`" prop="phone">
                  <el-input v-model="form.phone" type="number" clearable></el-input>
                </el-form-item>
                <el-form-item :label="`${$store.state.curIndex==1?'Parent\'s  Email Address':'学生/家长的联系方式（邮箱）'}：`" prop="email">
                  <el-input v-model="form.email" clearable></el-input>
                </el-form-item>
                <el-form-item :label="`${$store.state.curIndex==1?'Day and Time for a Class or Trial':'想要试课的时间（例，周三xx点-xx点）'}：`" prop="reservationTime">
                  <el-input v-model="form.reservationTime" clearable></el-input>
                </el-form-item>
                <el-form-item :label="`${$store.state.curIndex==1?'Expected Teaching Method (lf Any)':'期望教学方式'}：`" prop="teachingWay">
                  <el-input v-model="form.teachingWay" clearable></el-input>
                </el-form-item>
                <el-form-item :label="`${$store.state.curIndex==1?'Past Music Experience (lf Any)':'过往学习经历'}：`" prop="studyExperience">
                  <el-input type="textarea" v-model="form.studyExperience" :rows="4"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="info" size="medium" @click="onSubmit">{{$store.state.curIndex==1?'submit':'提交'}}</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>





        <!--        <div class="box-jump">-->
        <!--          <p>If you have further questions about our Music Theory classes or would like to see an outline of the curriculum, please contact us. We’d be happy to answer all of your questions!</p>-->
        <!--          <div class="jump-buttons">联系我们</div>-->
        <!--        </div>-->
      </div>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "HomeIndex",
  data() {
    return {
      formRules: {
        userName: [
          { required: true, message: '请输入学生姓名', trigger: ['blur','change']}
        ],
        phone: [
          { required: true, message: '请输入学生/家长的联系方式（手机号）', trigger: ['blur','change']}
        ],
      },
      form: {
        course: []
      },
      imageUrl: '',
      descInfo: '联系我们',
      acctivateName: "small",
      aiTitleSmallList: [],
      activeIndex: 0,
      carouselItems1:"",
      carouselItems2: "",
      applyObj: {},
      currentPage: 1,
      pageSize: 2,
      type: 10,
      imgs: [],
      homepageSceneList:[],
      background:[],

    }
  },
  props: {},
  methods: {
    imgPath() {
      return 'http://hjjai.com:6789/commonImg/'
    },
    onSubmit(){
      this.$refs.submitForm.validate(async (valid) => {
        if (valid) {
          console.log('valid',valid)
          let data = this.form
          data.course = data.course.join(',')
          const result = await axios.post('https://piano.100yueoo.com/prod-api/course', data)
          console.log('result',result)
          if(result.data.code=='200') {
            this.$store.commit('setIndex',6)
            this.$router.push({path:'/SuccessViews'})
          }else {
            this.$message.error(result.data.msg)
          }
        }
      });

    },


  },

  components: {
  },
  computed: {

  }
}
</script>

<style lang="less" scoped>
.colPos {
  position: relative !important;
  height: 600px;
  .is-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(33, 27, 23, .21);
  }
  img {
    //position: relative;
    //
    //top: -260px;
    margin-top: 5%;
  }

}

.main-content {
  background: #ffffff;
  padding-top: .8em;
  margin-bottom: 3em;
  .main-content-box {
    max-width: 1020px;
    width: auto;
    padding:36px 28px !important;
    margin: auto;
    .imgLists {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      flex-wrap: wrap;
      .imgLists-item {
        width: 31%;
        img {
          width: 100%;
          margin-bottom: .6em;
        }

      }
    }
    .box-imgs {
      margin: 2.2em 0;
      img {
        width: 100%;
        height: auto;
      }
    }
    h1 {
      margin: 1.65em 0 .9em;
      white-space: pre-wrap;
      text-align: center;
      color: rgb(0,162,224);
      font-weight: 600;
      font-style: normal;
      font-size: 30px;
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      letter-spacing: 2px;
      text-transform: none;
      line-height: 1.2em;
    }
    .box-info {
      margin-bottom: 3.85em;
    }
    .box-info-item {
      margin-top: 3.5em;
    }
    h2 {
      margin: 1.8em 0 1.05em;
      white-space: pre-wrap;
      color: #000;
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 600;
      font-style: normal;
      font-size: 24px;
      letter-spacing: 2px;
      //text-transform: uppercase;
      line-height: 1.2em;
    }
    p,ul li {
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 300;
      font-style: normal;
      font-size: 17px;
      letter-spacing: 2px;
      line-height: 1.6em;
      color: #000;
      white-space: pre-wrap;
      margin-bottom: .62em;

    }
    h3 {
      display: inline-block !important;
      margin: 1.8em 0 .6em;
      white-space: pre-wrap;
      color: #000;
      font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight: 600;
      font-style: normal;
      font-size: 19px;
      letter-spacing: 2px;
      text-transform: uppercase;
      line-height: 1.3em;

      span {
        letter-spacing: 2px;
        font-size: 20px !important;
        font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      }
    }
    ul {
      margin-left: 36px;
      li {
        margin: 0.5em 0;
      }
    }
    .box-jump {
      margin: 2em 0;
      .jump-buttons {
        width: 150px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #111;
        border: 2px solid #111;
        font-size: 16px;
        border-radius: 2px;
        margin: 2.6em auto;
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }
  }
  .box-lines {
    width: 100%;
    height: 1px;
    background: #ccc;
    margin: 6px 0 26px;
  }
}
.desc-wrapper {
  animation: feature-text-anim .75s ease-in-out;
  top: 52%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-42%);
  width: 100%;
  max-width: 956px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  p {
    white-space: pre-wrap;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 600;
    font-style: normal;
    font-size: 56px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.3em;
    color: #fff;
    margin: 20px auto;
    -webkit-transform: translatez(0);
  }
}
/deep/ .el-form--label-top .el-form-item__label {
  font-size: 19px;
  white-space: pre-wrap;
  color: #000;
  font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
  font-weight: 400;
}
/deep/ .el-checkbox {

  display: block;

  .el-checkbox__label {
    font-size: 14px;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    white-space: pre-wrap;
    color: #000;
  }
}
/deep/ .el-radio {

  display: block;
  margin-bottom: 20px;

  .el-radio__label {
    font-size: 15px;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    white-space: pre-wrap;
    color: #000;
  }
}
/deep/ .el-button--medium{
  font-size: 19px;
  font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
  background: #000;
  padding: 20px 38px;
}
</style>
