import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView'
import Home from '@/views/Home'
import ContactUs from '../components/ContactUs.vue'
import CalendarArrangement from '../components/CalendarArrangement.vue'  //日历安排
import LeaveOfAbsence from '../components/LeaveOfAbsence.vue'  //学校政策
import TeachingRegulations from '../components/TeachingRegulations.vue'  //授课规定
import Information from '../components/Information.vue'  //资讯
import MusicTheoryLessons from '../components/MusicTheoryLessons.vue'  //乐理课
import PianoSparring from '../components/PianoSparring.vue'  //钢琴陪练
import PianoLessons from '../components/PianoLessons.vue'  //钢琴课
import CurriculumSystem from '../components/CurriculumSystem.vue'  //体系
import SoMeEstablish from '../components/SoMeEstablish.vue'  //some成立
import TeacherIntroduction from '../components/TeacherIntroduction.vue'  //老师介绍
import CampusIntroduction from '../components/CampusIntroduction.vue'  //校区介绍
import PrincipalIntroduced from '../components/PrincipalIntroduced.vue'  //校长介绍
import TrialLessons from '../components/TrialLessons.vue'  //试课
import ViolinLessons from '../components/ViolinLessons.vue'  //小提琴课
import SuccessViews from '../components/SuccessViews.vue'  //提交成功
import SchoolPolicy from '../components/SchoolPolicy.vue'  //提交成功
import FormDownload from '../components/FormDownload.vue'  //提交成功
import moreComment from '../components/moreComment.vue'  //提交成功


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: HomeView,
    children: [
      {
        path: '/',
        component: Home
      },
      {path: 'ContactUs',name :'contactUs',component: ContactUs},
      {path:'SoMeEstablish',name:'soMeEstablish',component:SoMeEstablish},
      {path:'PrincipalIntroduced',name:'principalIntroduced',component:PrincipalIntroduced},
      {path:'TeacherIntroduction',name:'teacherIntroduction',component:TeacherIntroduction},
      {path:'CampusIntroduction',name:'campusIntroduction',component:CampusIntroduction},
      {path:'CurriculumSystem',name:'curriculumSystem',component:CurriculumSystem},
      {path:'PianoSparring',name:'pianoSparring',component:PianoSparring},
      {path:'MusicTheoryLessons',name:'musicTheoryLessons',component:MusicTheoryLessons},
      {path:'Information',name:'information',component:Information},
      {path:'SchoolPolicy',name:'schoolPolicy',component:SchoolPolicy},
      {path:'TeachingRegulations',name:'teachingRegulations',component:TeachingRegulations},
      {path:'LeaveOfAbsence',name:'leaveOfAbsence',component:LeaveOfAbsence},
      {path:'CalendarArrangement',name:'calendarArrangement',component:CalendarArrangement},
      {path:'TrialLessons',name:'trialLessons',component:TrialLessons},
      {path:'ViolinLessons',name:'violinLessons',component:ViolinLessons},
      {path:'SuccessViews',name:'successViews',component:SuccessViews},
      {path:'PianoLessons',name:'pianoLessons',component:PianoLessons},
      {path:'FormDownload',name:'formDownload',component:FormDownload},
      {path:'moreComment',name:'moreComment',component:moreComment},

    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// 避免冗余路由
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch(err => err)

}

export default router
