<template xmlns="http://www.w3.org/1999/html">
  <div id="Home">
    <el-row class="rowPos">
      <el-col :span="24" class="colPos">
        <!--        <div class="is-background"></div>-->
<!--        <img src="@/assets/img/bg8.jpg" style="width: 100%;height: auto;">-->
        <div class="desc-wrapper">
          <p>{{descInfo}}</p>
        </div>
        <!--            <el-carousel height="600px" :interval=5000 style="z-index: -1;">-->
        <!--              <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">-->
        <!--&lt;!&ndash;            <el-carousel-item v-for="(item, index) in imgs" :key="index">&ndash;&gt;-->
        <!--&lt;!&ndash;                <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">&ndash;&gt;-->
        <!--&lt;!&ndash;            </el-carousel-item>&ndash;&gt;-->
        <!--        </el-carousel>-->
      </el-col>
      <!--      <div style="height: 600px;background-color: rgba(33,27,23,.21);"></div>-->
    </el-row>
    <div class="main-content">
      <div style="max-width: 1020px;width: auto;padding:0 28px !important;margin: auto">
        <h2>{{$store.state.curIndex==1?'Thank your':'谢谢你'}}！</h2>
        <p>{{$store.state.curIndex==1?'Thank you for your interest in taking a trial music lesson with us. Our team will contact you within 2 working days.':'谢谢你有兴趣参加我们的试听音乐课。我们的团队将在2个工作日内与您联系。'}}</p>
      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "HomeIndex",
  props: {
    ind: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inds: this.$route.query.ind || '',
      imageUrl: '',
      descInfo: 'Take a Trial',
      acctivateName: "small",
      aiTitleSmallList: [],
      activeIndex: 0,
      counts: 0,
      flag: false,
      carouselItems1:"",
      carouselItems2: "",
      applyObj: {},
      currentPage: 1,
      pageSize: 2,
      type: 10,
      imgs: [],
      homepageSceneList:[],
      background:[],

    }
  },
  methods: {
    imgPath() {
      return 'http://hjjai.com:6789/commonImg/'
    },
    steCarou(nub){
      this.carouselItems1 = this.carouselItems[nub].title;
      this.carouselItems2 = this.carouselItems[nub].content;
      console.log(this.carouselItems[nub].content);
      console.log(this.carouselItems[nub].title);

    },
    changeIn(val){
      // console.log(val);
      this.steCarou(val)
    },
    boxFiveDemo(event) {
      let obj = this.boxFive.filter((item) => {
        return item.id.toString() === event.target.id;
      });
      this.applyObj = obj[0];
      this.boxFive[event.target.id-1].Icon=true
    },

  },
  mounted() {
    //  this.flag = false
    //  console.log('1000',this.inds)
    // if(this.inds && !this.flag) {
    //
    //     var element = document.getElementById('elementId1');
    //   var distance = element.offsetTop;
    //   // var distance = parseFloat(style.getPropertyValue('top'));
    //
    //      console.log('distance',distance)
    //     window.scrollTo({
    //       top: distance+1600,
    //       left: 0,
    //       behavior: 'smooth' // 可选，平滑滚动效果
    //     });
    //     this.$forceUpdate()
    // }
  },
  activated() {

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth' // 可选，平滑滚动效果
    });

    this.$forceUpdate()
    // console.log('000',this.inds)
    if(this.inds) {

      var element = document.getElementById('elementId1');
      var distance = element.offsetTop;
      // var distance = parseFloat(style.getPropertyValue('top'));

      console.log('distance',distance)
      if(this.counts==0) {
        window.scrollTo({
          top: distance+680,
          left: 0,
          behavior: 'smooth' // 可选，平滑滚动效果
        });
      }else {
        window.scrollTo({
          top: distance-110,
          left: 0,
          behavior: 'smooth' // 可选，平滑滚动效果
        });
      }

      this.counts++
      this.$forceUpdate()
    }
  },
  components: {
  },
  watch: {
    inds(newVal) {
      console.log('----newVal',newVal)
    }
  },
  computed: {
  }
}
</script>

<style lang="less" scoped>
.colPos {
  position: relative !important;
  height: 600px;
  overflow: hidden;
  background: #1f1f1f;
  .is-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(33, 27, 23, .21);
  }
  img {
    //position: relative;
    //
    //top: -260px;
    margin-top: -13%;
  }

}

h1 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 30px;

  span {
    margin-right: 20px;
  }

}
p {
  white-space: pre-wrap;
  font-family: Microsoft YaHei;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 2px;
  margin-bottom: .72em;
  text-transform: none;
  line-height: 1.6em;
  color: #000;
  font-size: 17px;
}
.main-content {

  background: #ffffff;
  margin:0px auto 30px;
  padding-top: 10px;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: center;
  .imgLists3 {
    margin-top: 12px;
  }



}
p {
  margin-bottom: .9em;
  white-space: pre-wrap;
  font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  letter-spacing: 2px;
  line-height: 1.6em;
  color: #000;
}
h2 {
  color: rgb(0,162,224);
  margin: 3em 0 1em;
  font-family:Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
  font-weight:400;
  font-style: normal;
  font-size: 24px;
  letter-spacing: 2px;
  text-transform: none;
  line-height: 1.5em;
  //text-align: center;
  white-space: pre-wrap;
}
.main-content-infos-introd .introd-item2:nth-child(even) .introd-img2{
  float: left;
}
.desc-wrapper {
  animation: feature-text-anim .75s ease-in-out;
  top: 52%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-42%);
  width: 100%;
  max-width: 956px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  p {
    white-space: pre-wrap;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 400;
    font-style: normal;
    font-size: 56px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.3em;
    color: #fff;
    margin: 20px auto;
    -webkit-transform: translatez(0);
  }
}
</style>
