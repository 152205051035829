<template>
<div id="TopBar">

      <div class="flex-between topBar-nav">
        <div>
            <logo v-bind:logo="logo"></logo>
        </div>
        <div>
            <NavMenu v-if="$store.state.boxWidth>1200" v-bind:menuList="NavMenuList" @topClick="navMenuClick" :activeValue="this.$store.state.index"></NavMenu>
          <div style="display: flex;align-items: center">
            <div class="changeType" v-if="$store.state.boxWidth<1200">
              <div :class="{'ligntItem':$store.state.curIndex==1}" style="margin-right: 20px;cursor: pointer" @click="changeType(1)">EN</div>
              <div style="cursor: pointer" :class="{'ligntItem':$store.state.curIndex==2}" @click="changeType(2)">中</div>
            </div>
            <i style="font-size: 26px;color: rgb(0,162,224);margin: 0 10px" class="el-icon-s-unfold" v-if="$store.state.boxWidth<=1200 && !$store.state.showMenu" @click="openMenuList"></i>
            <i style="font-size: 24px;margin:0 10px" class="el-icon-close" v-if="$store.state.showMenu" @click="closeMenuList"></i>

          </div>        </div>

</div>

</div>
</template>

<script>
import NavMenu from './NavMenu.vue';
import Logo from './Logo.vue'
// import IndustrySolutionsHover from '@/components/IndustrySolutionsHover'
export default {
    name: "TopBar",
    data() {
        return {

            isRemove: this.$store.state.isRemove,
            isXdfa:this.$store.state.isXdfa
        }
    },
    props: {
        logo: String
    },
    components: {
        NavMenu,
        Logo
    },
    methods: {
      changeType(type) {
        this.$store.commit('setCurIndex',type)
      },
      openMenuList() {
           this.$store.commit("setShowMenu", true)
        },
      closeMenuList() {
           this.$store.commit("setShowMenu", false)
        },

        navMenuClick(even) {
            let indexValue = even.$el.attributes.name.value
            let index = this.$store.state.index
            if (index === indexValue) return
            this.$store.commit("setIndex", indexValue)
            // console.log("store修改后的值"+this.$store.state.index);
            let ph = even.$el.attributes.path.value
            this.$router.push({
                path: ph
            })
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth' // 可选，平滑滚动效果
          });
        },
        addCls(ele,setNameVal) {
            // console.log(ele.classList);
            ele.classList.remove("isActive")
            this.$store.commit(setNameVal, false)
        },
        removeCls(ele,setNameVal) {
            // console.log(this.classList);
            if (this.isRemove) {
                ele.classList.add("isActive")
                this.$store.commit(setNameVal, true)
            } else return
        },
        BarHoverEle(ele, obj) {
            ele.addEventListener("mouseenter", () => {
                this.addCls(obj,"setIsRemover")
            })
            ele.addEventListener("mouseleave", () => {
                setTimeout(this.removeCls(obj,"setIsRemover"), 1000)
            })

        },
        DISHoverEle(ele) {
            ele.addEventListener("mouseenter", () => {
                this.addCls(ele,"setIsXdfa")
            })
            ele.addEventListener("mouseleave", () => {
                this.removeCls(ele,"setIsXdfa")
            })
        }
    },

    mounted() {
        // let ele = document.getElementById("hxcp")
        let eleCore = document.getElementById("isActive")
        // let ISH = document.getElementById("isISH")
        // let xdfa = document.getElementById("xdfa")
        // this.BarHoverEle(ele,eleCore)
        // this.BarHoverEle(xdfa,ISH)
        this.DISHoverEle(eleCore)
      // this.$store.commit("setIndex", '1')
      // this.$router.push({path:''})
        // this.DISHoverEle(ISH)
        // ele.addEventListener("mouseenter", () => {
        //     this.addCls(eleCore)
        // })
        // ele.addEventListener("mouseleave", () => {
        //     setInterval(this.removeCls(eleCore), 1000)
        // })
        // eleCore.addEventListener("mouseenter", () => {
        //     this.addCls(eleCore)
        // })
        // eleCore.addEventListener("mouseleave", () => {
        //     this.removeCls(eleCore)
        // })

    },
  computed: {
    NavMenuList(){
      if(this.$store.state.curIndex==1) {
        return [{
          id: "sy",
          index: "1",
          title: "HOME",
          path: "/",

        },
          {
            id: "gysm",
            index: "2",
            title: "ABOUT SOMe",
            lists:[
              // {id:'smcl',index:"2-1",title:'SoMe成立',path:'/SoMeEstablish'},
              {id:'xzjs',index:"2-1",title:'Our Founder',path:'/PrincipalIntroduced'},
              {id:'lsjs',index:"2-2",title:'our Teachers',path:'/TeacherIntroduction'},
              {id:'xqjs',index:"2-3",title:'our studio',path:'/CampusIntroduction'},
            ]

          },
          {
            id: "kctx",
            index: "3",
            title: "WHAT WE OFFER",
            lists:[
              {id:'gqk',index:"3-1",title:'Piano',path:'/PianoLessons'},
              {id:'gqpl',index:"3-2",title:'Practice Partner',path:'/PianoSparring'},
              {id:'xtq',index:"3-3",title:'Violin',path:'/ViolinLessons'},
              {id:'ylk',index:"3-4",title:'Music Theory',path:'/MusicTheoryLessons'},
            ]
          },
          {
            id: "xxzc",
            index: "4",
            title: "Student INFO",
            lists:[
              {id:'skgd',index:"4-1",title:'School Politics',path:'/SchoolPolicy'},
              {id:'rlap',index:"4-2",title:'school Calendar',path:'/CalendarArrangement'},
              {id:'bgxz',index:"4-3",title:'Forms',path:'/FormDownload'},
            ]
          },
          {
            id: "zx",
            index: "5",
            title: "NEWS",
            path: "/Information"
          },
          {
            id: "lxwm",
            index: "6",
            title: "CONTACT US",
            path: "/ContactUs"
          }
        ]
      }else {
        return [{
          id: "sy",
          index: "1",
          title: "首页",
          path: "/",

        },
          {
            id: "gysm",
            index: "2",
            title: "关于SoMe",
            lists:[
              // {id:'smcl',index:"2-1",title:'SoMe成立',path:'/SoMeEstablish'},
              {id:'xzjs',index:"2-1",title:'校长介绍',path:'/PrincipalIntroduced'},
              {id:'lsjs',index:"2-2",title:'老师介绍',path:'/TeacherIntroduction'},
              {id:'xqjs',index:"2-3",title:'校区介绍',path:'/CampusIntroduction'},
            ]

          },
          {
            id: "kctx",
            index: "3",
            title: "课程体系",
            lists:[
              {id:'gqk',index:"3-1",title:'钢琴课',path:'/PianoLessons'},
              {id:'gqpl',index:"3-2",title:'钢琴陪练',path:'/PianoSparring'},
              {id:'xtq',index:"3-3",title:'小提琴课',path:'/ViolinLessons'},
              {id:'ylk',index:"3-4",title:'乐理课',path:'/MusicTheoryLessons'},
            ]
          },
          {
            id: "xxzc",
            index: "4",
            title: "学校政策",
            lists:[
              {id:'skgd',index:"4-1",title:'学校规定',path:'/SchoolPolicy'},
              {id:'rlap',index:"4-2",title:'校历安排',path:'/CalendarArrangement'},
              {id:'bgxz',index:"4-3",title:'表格下载',path:'/FormDownload'},
            ]
          },
          {
            id: "zx",
            index: "5",
            title: "资讯",
            path: "/Information"
          },
          {
            id: "lxwm",
            index: "6",
            title: "联系我们",
            path: "/ContactUs"
          }
        ]
      }
    }
  },
}
</script>

<style lang="less" scoped>
.el-row {
    display: flex;
    align-items: center;
}

.el-col {
    height: 100% !important;
}

#TopBar {
    width: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
  box-shadow: 6px 6px 6px rgba(0,0,0,.1);
    background-color: rgb(255, 255, 255);
}

.isActive {
    display: none;
}

.pos {
    position: absolute;
    top: 60px;
    left: 28%;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topBar-nav {
  padding:0 30px;
  box-sizing: border-box;
  height: 100px;
}
.changeType {
  //margin-left: 15px;
  width: 72px;
  display: flex;
  color: #666;
  font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
  font-size: 16px;
  .ligntItem {
    font-weight: bold;
    color: rgba(0,162,224,1);
    margin-right: 20px;
  }
}
</style>
