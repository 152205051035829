<template xmlns="http://www.w3.org/1999/html">
  <div id="Home">
    <el-row class="rowPos">
      <el-col :span="24" class="colPos">
        <!--        <div class="is-background"></div>-->
        <img src="@/assets/img/xiaoqu1.jpg" style="width: 100%;height: auto;" class="back1">
        <div class="desc-wrapper">
          <p>{{$store.state.curIndex==1?'Our Studio':'校区介绍'}}</p>
        </div>
        <!--            <el-carousel height="600px" :interval=5000 style="z-index: -1;">-->
        <!--              <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">-->
        <!--&lt;!&ndash;            <el-carousel-item v-for="(item, index) in imgs" :key="index">&ndash;&gt;-->
        <!--&lt;!&ndash;                <img src="@/assets/img/backgroud1.jpg" style="width: 100%;height: 100%;">&ndash;&gt;-->
        <!--&lt;!&ndash;            </el-carousel-item>&ndash;&gt;-->
        <!--        </el-carousel>-->
      </el-col>
      <!--      <div style="height: 600px;background-color: rgba(33,27,23,.21);"></div>-->
    </el-row>
    <div class="main-content">
      <div style="max-width: 1020px;width: auto;padding:0 24px !important;margin: auto">
        <div class="main-content-infos sqs-layout">
          <p>{{$store.state.curIndex==1?'SoMe Music School is an institution dedicated to providing high-quality, intelligent music education services. Since establishing its Southeast Asia headquarters in Singapore in 2022. it has earned widespread recognition and praise':'SoMe音乐学校，是一家致力于提供高质量、智能化音乐教育服务的机构。自2022年在新加坡设立东南亚总部以来，已经赢得了广泛的认可与赞誉。'}}</p>
          <p>{{$store.state.curIndex==1?'We have assembled an elite teaching team composed of experienced music education experts from both China and Singapore. Each teacher not only possesses extensive teaching experience but also holds professional qualifications, Our teaching model combines both online and offline methods fostering students\' musical interests and abilities while laying a solid foundation for their music careers and future development.':'我们汇聚了一支由中国与新加坡资深音乐教育专家组成的精英教师团队，每位老师不仅拥有丰富的教学经验和专业资质。教学模式采用线上和线下相结合的方式，培养学生的音乐兴趣和能力，为学生的音乐生涯和未来发展打下坚实的基础。'}}</p>
          <p>{{$store.state.curIndex==1?'SoMe Music School has hosted several student concerts, outdoor music camps, and performances during International Week at Singapore international Schools. Additionally, we have collaborated the New Year\'s Music Concert with the Chinese Embassy in Singapore.':'SoMe音乐学校举办数次学生音乐会，户外音乐活动营，新加坡国际学校国际周演出承办，协办中国驻新加坡大使馆新年音乐会。'}}</p>
          <!--          <h2>-->
          <!--            Here are the heroes on our team who make Joy Music Studio a GREAT place to learn music and realise musical dreams.            </h2>-->
          <div class="main-content-infos-introd">
            <div class="introd-item">
              <div class="introd-item2">
                <div class="introd-item-info2">
                  <p>{{$store.state.curIndex==1?'The school features spacious classrooms, comfortable waiting areas, and a small recording space with a Boston grand piano, as well as a 10-12 person sharing space for events. There are also three shared practice rooms and one ideal practice space for introverted students.':'宽敞的教室，舒适的等候空间，波士顿三角钢琴的小型录音空间+10-12人的分享会空间。3间共享琴房，1人最佳练琴空间。'}}</p>
                </div>
                <h2>{{$store.state.curIndex==1?'Exterior of the Campus':'校区外部'}}</h2>

                <img src="@/assets/img/back1.jpg" style="width: 100%">
                <div style="display: flex;flex-wrap: wrap;justify-content: space-between;margin-top: 15px">
                  <img class="img-1" src="@/assets/img/waibu1.jpg" style="width: 32%;margin-bottom: 16px">
                  <img class="img-1" src="@/assets/img/waibu2.jpg" style="width: 32%;margin-bottom: 16px">
                  <img class="img-1" src="@/assets/img/waibu3.jpg" style="width: 32%;margin-bottom: 16px">
                </div>
                <h2>{{$store.state.curIndex==1?'Interior of the Campus':'校区内部'}}校区内部</h2>
                <div style="display: flex;flex-wrap: wrap;justify-content: space-between;">
                  <img class="img-1" src="@/assets/img/neibu2.jpg" style="width: 32%;margin-bottom: 16px">
                  <img class="img-1" src="@/assets/img/neibu1.jpg" style="width: 32%;margin-bottom: 16px">
                  <img class="img-1" src="@/assets/img/neibu3.jpg" style="width: 32%;margin-bottom: 16px">
                </div>
                <div style="display: flex;flex-wrap: wrap;justify-content: space-between;">
                  <img class="img-1" src="@/assets/img/neibu4.jpg" style="width: 32%;margin-bottom: 16px">
                  <img class="img-1" src="@/assets/img/neibu6.jpg" style="width: 32%;margin-bottom: 16px">
                  <img class="img-1" src="@/assets/img/neibu5.jpg" style="width: 32%;margin-bottom: 16px">
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "HomeIndex",
  props: {
    ind: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inds: this.$route.query.ind || '',
      imageUrl: '',
      descInfo: '校区介绍',
      acctivateName: "small",
      aiTitleSmallList: [],
      activeIndex: 0,
      counts: 0,
      flag: false,
      carouselItems1:"",
      carouselItems2: "",
      applyObj: {},
      currentPage: 1,
      pageSize: 2,
      type: 10,
      imgs: [],
      homepageSceneList:[],
      background:[],

    }
  },
  methods: {
    imgPath() {
      return 'http://hjjai.com:6789/commonImg/'
    },
    steCarou(nub){
      this.carouselItems1 = this.carouselItems[nub].title;
      this.carouselItems2 = this.carouselItems[nub].content;
      console.log(this.carouselItems[nub].content);
      console.log(this.carouselItems[nub].title);

    },
    changeIn(val){
      // console.log(val);
      this.steCarou(val)
    },
    boxFiveDemo(event) {
      let obj = this.boxFive.filter((item) => {
        return item.id.toString() === event.target.id;
      });
      this.applyObj = obj[0];
      this.boxFive[event.target.id-1].Icon=true
    },

  },
  mounted() {
    //  this.flag = false
    //  console.log('1000',this.inds)
    // if(this.inds && !this.flag) {
    //
    //     var element = document.getElementById('elementId1');
    //   var distance = element.offsetTop;
    //   // var distance = parseFloat(style.getPropertyValue('top'));
    //
    //      console.log('distance',distance)
    //     window.scrollTo({
    //       top: distance+1600,
    //       left: 0,
    //       behavior: 'smooth' // 可选，平滑滚动效果
    //     });
    //     this.$forceUpdate()
    // }
  },
  activated() {

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth' // 可选，平滑滚动效果
    });

    this.$forceUpdate()
    // console.log('000',this.inds)
    if(this.inds) {

      var element = document.getElementById('elementId1');
      var distance = element.offsetTop;
      // var distance = parseFloat(style.getPropertyValue('top'));

      console.log('distance',distance)
      if(this.counts==0) {
        window.scrollTo({
          top: distance+680,
          left: 0,
          behavior: 'smooth' // 可选，平滑滚动效果
        });
      }else {
        window.scrollTo({
          top: distance-110,
          left: 0,
          behavior: 'smooth' // 可选，平滑滚动效果
        });
      }

      this.counts++
      this.$forceUpdate()
    }
  },
  components: {
  },
  watch: {
    inds(newVal) {
      console.log('----newVal',newVal)
    }
  },
  computed: {
  }
}
</script>

<style lang="less" scoped>
.colPos {
  position: relative !important;
  height: 600px;
  overflow: hidden;
  .is-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(33, 27, 23, .21);
  }
  img {
    //position: relative;
    //
    //top: -260px;
    margin-top: -13%;
  }

}

h1 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 30px;

  span {
    margin-right: 20px;
  }

}
p {
  white-space: pre-wrap;
  font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 2px;
  margin-bottom: .8em;
  text-transform: none;
  line-height: 1.6em;
  color: #000;
  text-align: left;
  text-indent: 2em;
  font-size: 17px;
}
.main-content {

  background: #ffffff;
  margin:0px auto 30px;
  padding-top: 10px;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: center;
  .imgLists3 {
    margin-top: 12px;
  }
  .imgLists {
    display: flex;
    margin-top: 12px;
    //align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .imgLists-item {
      width: 31%;
      img {
        width: 100%;
        margin-bottom: .6em;
      }
    }
    .imgLists-item2 {
      width: 48%;
    }
  }
  &-infos {
    padding: 28px 0;
    width: 88%;
    margin: auto;
    box-sizing: border-box;
    &-introd {
      margin-top: 3.88em;
      .introd-item {
        .introd-item-title {
          h1 {
            margin: 2.98em 0 1.1em;
            font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
            color: rgb(0,162,224);
            font-weight: 600;
            text-align: center;
            font-style: normal;
            font-size: 30px;
            letter-spacing: 2px;
            text-transform: none;
            line-height: 1.3em;
            white-space: pre-wrap;
          }
        }
        .introd-item2 {
          position: relative;
          z-index: 11;
          margin-bottom: 3.82em;
          .introd-item-title2 {
            color: rgb(0,162,224);
            font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
            font-weight: 600;
            font-style: normal;
            font-size: 18px;
            letter-spacing: 2px;
            text-transform: uppercase;
            line-height: 1.3em;
            white-space: pre-wrap;
            margin-bottom: .7em;
          }
          .introd-item-info {
            p {
              margin-bottom: .72em;
              white-space: pre-wrap;
              font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
              font-weight: 300;
              font-style: normal;
              font-size: 17px;
              letter-spacing: 2px;
              line-height: 1.6em;
              color: #000;
            }
          }
          .introd-item-info2 {
            p {
              margin-bottom: .72em;
              white-space: pre-wrap;
              font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
              font-weight: 300;
              font-style: normal;
              font-size: 18px;
              letter-spacing: 2px;
              line-height: 1.6em;
              color: #000;
            }
            ol {
              margin-left: 36px;
            }
            li {
              margin-bottom: .5em;
              white-space: pre-wrap;
              font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
              font-weight: 300;
              font-style: normal;
              font-size: 16px;
              letter-spacing: 2px;
              line-height: 1.3em;
              color: #000;
            }
          }
        }
        .introd-img {
          float: right;
          position: relative;
          z-index: 11;
          //padding: 30px;
          box-sizing: border-box;
          width: 38%;
          height: 290px;
          //overflow: hidden;
          /* clip-path: url(#yui_3_17_2_1_1680071662615_128003); */
          //-webkit-clip-path: url(#yui_3_17_2_1_1680071662615_128003);
          img {
            margin: 20px 24px;
            display: block;
            object-fit: cover;
            width: 80%;
            height: 270px;
            border-radius: 100%;
            object-position: 100% 12.40276909221658%;
            position: absolute;
            top: 0;
            left: 0;
            max-width: none;
          }
        }
        .introd-img2 {
          float: right;
          position: relative;
          z-index: 11;
          //padding: 30px;
          box-sizing: border-box;
          width: 36%;
          height: 210px;
          //overflow: hidden;
          /* clip-path: url(#yui_3_17_2_1_1680071662615_128003); */
          //-webkit-clip-path: url(#yui_3_17_2_1_1680071662615_128003);
          img {
            margin: 20px 24px 0px;
            display: block;
            object-fit: cover;
            width: 78%;
            height: 202px;
            border-radius: 100%;
            object-position: 100% 32.40276909221658%;
            position: absolute;
            top: 0;
            left: 0;
            max-width: none;
          }
        }

      }
    }
    h2 {
      color: #000;
      margin: 1.56em 0 .5em;
      font-family:Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
      font-weight:600;
      font-style: normal;
      font-size: 24px;
      letter-spacing: 2px;
      text-transform: none;
      line-height: 1.5em;
      //text-align: center;
      white-space: pre-wrap;
    }
  }

}
.main-content-infos-introd .introd-item2:nth-child(even) .introd-img2{
  float: left;
}
.desc-wrapper {
  animation: feature-text-anim .75s ease-in-out;
  top: 52%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-42%);
  width: 100%;
  max-width: 956px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  p {
    white-space: pre-wrap;
    font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
    font-weight: 600;
    font-style: normal;
    font-size: 56px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.3em;
    text-align: center;
    color: #fff;
    margin: 20px auto;
    -webkit-transform: translatez(0);
  }
}
</style>
