<template>
  <div class="bottom-nav">
    <div class="bottom-nav-item" @click="emailFn">
      <div class="el-icon-message" style="font-size: 16px;"></div>
      <div class="titles">{{$store.state.curIndex==1?'email':'邮件'}}</div>
    </div>
    <div class="bottom-nav-item" @click="callFn">
      <div class="el-icon-phone-outline" style="font-size: 16px;"></div>
      <div class="titles">{{ $store.state.curIndex==1?'call':'电话'}}</div>
    </div>
    <div class="bottom-nav-item" @click="addressFn">
      <div class="el-icon-location-outline" style="font-size: 16px;"></div>
      <div class="titles">{{ $store.state.curIndex==1?'address':'地址'}}</div>
    </div>
    <div class="bottom-nav-item" @click="hoursFn">
      <div class="el-icon-time" style="font-size: 16px;text-align: center"></div>
      <div class="titles">{{ $store.state.curIndex==1?'hours':'时间'}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
    }
  },
  methods:{
    callFn() {
      var a = document.createElement("a");
      a.href="tel:+65 80398964"   //动态赋值
      a.click()
    },
    emailFn() {
       var a = document.createElement("a");
       a.href="mailto:somemusicschool@gmail.com"   //动态赋值
       a.click()
    },
    addressFn() {
     this.$emit('openFn',1)
    },
    hoursFn() {
      this.$emit('openFn',2)
    }
  }
}
</script>
<style scoped lang="less">


.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 111;
  background: rgba(0,0,0,.96);
  height: 60px;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: Microsoft Yahei,Microsoft JhengHei,SimSun,Helvetica;
  animation: enter 0.5s;
  font-size: 13px;
  &-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .titles {
    text-align: center;
    margin-top: 3px;
    text-transform: uppercase;
  }
}

</style>
