import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import '@/assets/css/fonts.css'
import '@/assets/css/site.css'
Vue.config.productionTip = false
Vue.use(ElementUI);
axios.defaults.baseURL=""

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
