<template>
<div class="HomeView" id="HomeViews" :class="{'HomeView2':$store.state.showMenu}">
    <el-container :class="{'leftContainer':$store.state.showMenu}">
        <el-header v-if="$store.state.showNav">
            <TopBar></TopBar>
        </el-header>
        <el-main @click.native="closeMenu" style="overflow:hidden;" class="tests">
            <Keep-Alive>
                <router-view :key="$route.fullPath"></router-view>
            </Keep-Alive>
        </el-main>
        <el-footer style="height: auto">
            <BottomBar v-if="$store.state.showNav"></BottomBar>
        </el-footer>
      <BottomNav @openFn="openFns" v-if="$store.state.boxWidth<460 && tops==0"></BottomNav>

        <el-backtop ></el-backtop>

      <div class="button-fixed" v-if="$route.fullPath!=='/TrialLessons' && $route.fullPath!=='/SuccessViews'" @click="goFun">{{$store.state.curIndex==1?'TRIAL LESSON':'试 课 申 请'}}</div>
    </el-container>
  <div v-if="$store.state.showMenu" style="width: 42%;padding: 45px 0;margin-left:-350px;box-sizing: border-box;overflow-y: auto;overflow-x: hidden">
    <NavMenu v-bind:menuList="NavMenuList" @topClick="navMenuClick" :activeValue="this.$store.state.index"></NavMenu>
  </div>

  <el-dialog   :visible.sync="dialogVisible"
               top="0px"
               :fullscreen="true"
               :modal-append-to-body="true"
               width="100%">
    <div class="content1" v-if="types==1">
      <div class="titles">
        SoMe Music School
      </div>
      <div>
        66 E Coast Rd, B1-02 the Flow, SG 428778
      </div>
    </div>
  </el-dialog>
    <!-- 未实现拖拽移动和数据交互 -->
<!--    <OnlineConsultation id="OnlineConsultation" class="isShow onlineConsultationPosition"></OnlineConsultation>-->
</div>
</template>

<script>
import TopBar from '@/components/TopBar'
import BottomBar from '@/components/BottomBar'
import NavMenu from "@/components/NavMenu.vue";
import BottomNav from "@/components/BottomNav.vue";
export default {
    name: "HomeView",
    data() {
        return {
          tops: 0,
          types: 1,
          dialogVisible: false,

          rightMenu: this.$store.state.showMenu,
          windowWidth: document.body.clientWidth
        }
    },
    components: {
      NavMenu,
        TopBar,
        BottomBar,
      BottomNav
    },
  watch: {
    windowWidth(val) {
      if(val<1200) {
        console.log('val',val)
        this.rightMenu = true
      }else {
        this.rightMenu = false
      }
    }
  },
  computed: {
    NavMenuList(){
      if(this.$store.state.curIndex==1) {
        return [{
          id: "sy",
          index: "1",
          title: "HOME",
          path: "/",

        },
          {
            id: "gysm",
            index: "2",
            title: "ABOUT SOMe",
            lists:[
              // {id:'smcl',index:"2-1",title:'SoMe成立',path:'/SoMeEstablish'},
              {id:'xzjs',index:"2-1",title:'Our Founder',path:'/PrincipalIntroduced'},
              {id:'lsjs',index:"2-2",title:'our Teachers',path:'/TeacherIntroduction'},
              {id:'xqjs',index:"2-3",title:'our studio',path:'/CampusIntroduction'},
            ]

          },
          {
            id: "kctx",
            index: "3",
            title: "WHAT WE OFFER",
            lists:[
              {id:'gqk',index:"3-1",title:'Piano',path:'/PianoLessons'},
              {id:'gqpl',index:"3-2",title:'Practice Partner',path:'/PianoSparring'},
              {id:'xtq',index:"3-3",title:'Violin',path:'/ViolinLessons'},
              {id:'ylk',index:"3-4",title:'Music Theory',path:'/MusicTheoryLessons'},
            ]
          },
          {
            id: "xxzc",
            index: "4",
            title: "Student INFO",
            lists:[
              {id:'skgd',index:"4-1",title:'School Politics',path:'/SchoolPolicy'},
              {id:'rlap',index:"4-2",title:'school Calendar',path:'/CalendarArrangement'},
              {id:'bgxz',index:"4-3",title:'Forms',path:'/FormDownload'},
            ]
          },
          {
            id: "zx",
            index: "5",
            title: "NEWS",
            path: "/Information"
          },
          {
            id: "lxwm",
            index: "6",
            title: "CONTACT US",
            path: "/ContactUs"
          }
        ]
      }else {
        return [{
          id: "sy",
          index: "1",
          title: "首页",
          path: "/",

        },
          {
            id: "gysm",
            index: "2",
            title: "关于SoMe",
            lists:[
              // {id:'smcl',index:"2-1",title:'SoMe成立',path:'/SoMeEstablish'},
              {id:'xzjs',index:"2-1",title:'校长介绍',path:'/PrincipalIntroduced'},
              {id:'lsjs',index:"2-2",title:'老师介绍',path:'/TeacherIntroduction'},
              {id:'xqjs',index:"2-3",title:'校区介绍',path:'/CampusIntroduction'},
            ]

          },
          {
            id: "kctx",
            index: "3",
            title: "课程体系",
            lists:[
              {id:'gqk',index:"3-1",title:'钢琴课',path:'/PianoLessons'},
              {id:'gqpl',index:"3-2",title:'钢琴陪练',path:'/PianoSparring'},
              {id:'xtq',index:"3-3",title:'小提琴课',path:'/ViolinLessons'},
              {id:'ylk',index:"3-4",title:'乐理课',path:'/MusicTheoryLessons'},
            ]
          },
          {
            id: "xxzc",
            index: "4",
            title: "学校政策",
            lists:[
              {id:'skgd',index:"4-1",title:'学校规定',path:'/SchoolPolicy'},
              {id:'rlap',index:"4-2",title:'校历安排',path:'/CalendarArrangement'},
              {id:'bgxz',index:"4-3",title:'表格下载',path:'/FormDownload'},
            ]
          },
          {
            id: "zx",
            index: "5",
            title: "资讯",
            path: "/Information"
          },
          {
            id: "lxwm",
            index: "6",
            title: "联系我们",
            path: "/ContactUs"
          }
        ]
      }
      }
  },
  methods: {
    openFns(type) {
      this.types = type
      this.dialogVisible = true
    },
    navMenuClick(even) {
      let indexValue = even.$el.attributes.name.value
      let index = this.$store.state.index
      // console.log("原本value"+indexValue,"store的值"+index);
      if (index === indexValue) return
      this.$store.commit("setIndex", indexValue)
      // console.log("store修改后的值"+this.$store.state.index);
      let ph = even.$el.attributes.path.value
      this.$router.push({
        path: ph
      })
      this.$store.commit("setShowMenu", false)
    },
    goFun() {
      // this.$router.push({path:'/TrialLessons'})
      window.open(window.location.origin+'/#/TrialLessons')
      // console.log('-----',window.location)
    },
    closeMenu() {
      this.$store.commit('setShowMenu',false)
    },
    scroll() {
      // let top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      this.tops = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      //如果想实现页面被卷去多少某个div实现显示和隐藏即可在里面写判断配合v-show或v-if
    },
  },
    mounted() {
      const _this = this
      window.addEventListener('scroll', this.scroll);
      window.onresize = ()=>{
        console.log('========================',document.documentElement.clientWidth)
        _this.windowWidth = document.documentElement.clientWidth
        _this.$store.state.boxWidth = document.documentElement.clientWidth
      }
      if(window.location.hash==='#/TrialLessons') {
       this.$store.commit('setShowNav',false)
      }else {
        this.$store.commit('setShowNav',true)
      }
    }
}
</script>

<style lang="less" scoped>
.isShow {
    display: none;
}
.HomeView {
 overflow: hidden;
  .leftContainer {
    transform: translateX(-65%);
    overflow: hidden;
    width: 60%;
  }
}
.button-fixed {
  position: fixed;
  right: 30px;
  top: 70%;
  z-index: 111;
  color: rgba(0,162,224,1);
  border-radius: 96px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  //border: 1px solid #fff;
  background-color: #fff;
  line-height: 28px;
  padding: 20px 18px;
  font-family: Microsoft YaHei;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  box-sizing: border-box;
  white-space: pre-wrap;
  width: 99px;
  height: 99px;
  cursor: pointer;
}
.HomeView2 {
  display: flex;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}
.content1 {
  color: #999;
  white-space: pre-wrap;
  font-family: Microsoft YaHei;
  font-size: 14px;
  line-height: 1.6em;
  position: absolute;
  bottom: 36px;
  letter-spacing: 1px;
  .titles {
    color: #eee;
    font-size: 17px;
    margin-bottom: 6px;
    letter-spacing: 2px;
  }
}
/deep/ .el-dialog{
  //margin-top: 0 !important;
  background: #1f1f1f;
  .el-dialog__headerbtn .el-dialog__close {
    color: #ccc;
  }
}
/deep/ .el-backtop {
  z-index: 99 !important;
}
</style>
